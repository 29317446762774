import React from "react";
import entity from "../../services/entityService";
import { Link } from "react-router";
import {
  DeleteButton,
  EditButton,
  Picker
} from "../common/inputs";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Form from "../common/Form";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import LoadingSpinner from "../common/loaders/loadingSpinner";
import Modal from 'react-bootstrap/Modal';
import { contactDesignations, topHeaderAlert } from "../../config/predefinedValues.json";
import { TextInput } from "../common/inputs";
import ContactInvitation from "./_contactInvitation";
import PasswordReset from "./_passwordReset";
import SettingsContact from "./_settingsContact";
import auth from "../../services/authService";
import EditTeamMember from "../admin/ManageTeams/editTeamMember";

class EntityContacts extends Form {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fetchEntityContacts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entity != this.props.entity) {
      this.fetchEntityContacts();
    }
  }

  schema = Joi.object()
    .keys({
      first_name: Joi.string().required().label("First Name"),
      email: Joi.string().email().required().label("Contact Email"),
      phone: Joi.number().required().label("Contact Phone number"),
    })
    .unknown(true);

  state = {
    contact: {},
    deleteMethod: entity.deleteEntityContact,
    parentEntityContacts: [],
    selectedcontact: null,
    contactEdit: false,
    clientInvitationModal: false,
    showInvitation: false,
    passwordResetModal: false,
    settingsModal: false,
    selectedDesignation: { label: "Select designation", value: null },
    selectedParent: { label: "Select parent", value: null },
    selectedCategory: { label: "Select a Category", value: null },
    categories: [{ label: "Reserved", value: "reserved" }, { label: "Pooled", value: "pooled" }],
    updateModal: false,
    selectedStatus: { label: "Active", value: "active" },
    statuses: [{ label: "Active", value: "active" }, { label: "Inactive", value: "inactive" }]
  };

  // Handle input change for new contact fields
  handleChange = ({ currentTarget: input }) => {
    const contact = { ...this.state.contact };
    contact[input.name] = input.value;
    this.setState({ contact });
  };

  //  Update text fields for selected row
  handleUpdateTextField = ({ currentTarget: input }) => {
    let index = this.state.data.findIndex(item => item.edit === true);
    if (index !== -1) {
      const data = [...this.state.data];
      data[index][input.name] = input.value;
      this.setState({ data });
    }
  };

  // Update designation field for selected row
  handleUpdateDesignation = ({ value }) => {
    let index = this.state.data.findIndex(item => item.edit === true);
    if (index !== -1) {
      const data = [...this.state.data];
      data[index]['designation'] = { label: value, value };
      this.setState({ data });
    }
  }

  // Update status field for selected row
  handleUpdateStatus = ({ value }) => {
    let index = this.state.data.findIndex(item => item.edit === true);
    if (index !== -1) {
      const data = [...this.state.data];
      data[index]['status'] = { label: value, value };
      this.setState({ data });
    }
  }

  // Update category field for selected row
  handleUpdateCategory = ({ value }) => {
    let index = this.state.data.findIndex(item => item.edit === true);
    if (index !== -1) {
      const data = [...this.state.data];
      data[index]['category'] = { label: value, value };
      this.setState({ data });
    }
  }
  // ----------------------------------------------------- //

  /*----------------- Form validation -----------------*/
  validate = () => {
    const options = { abortEarly: true };
    const { error } = Joi.validate(this.state.contact, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
      toast.error(item.message);
    }
    return errors;
  };
  /* -------------------------------------------------*/

  refreshGrid = () => {
    this.fetchEntityContacts();
  };

  handleCheckboxChange = async (event, field) => {

    let contacts = this.state.data;
    if (field == "primary") {

      contacts.forEach((contact) => {
        if (contact.contact_id == event.target.name)
          contact.is_primary = event.target.checked;
        else contact.is_primary = false;
      });

      this.props.handlePrimaryChanged && this.props.handlePrimaryChanged();
    }

    await entity.handleContactCheckboxChange(event.target.name, event.target.checked, field, this.props.entity);
    this.setState({ data: contacts, ajaxRequest: false });
  };

  // -------------- Async function to fetch all Team members ---------------- //
  fetchEntityContacts = async (search = null,selectedStatus='active') => {
    try {
      this.setState({ ajaxRequest: true });
      const { data } = await entity.getEntityContactsList(this.props.entity, search,selectedStatus);
      this.setState({ data: data.rows, ajaxRequest: false });
    } catch (error) { }
  };

  // ---------------------- Handler to update fields ---------------------- //
  handleEdit = (contact_id) => {
    let index = this.state.data.findIndex(item => item.contact_id === contact_id);
    if (index !== -1) {
      const data = [...this.state.data];
      const contactInfo = this.state.data.find(item => item.contact_id === contact_id);
      data[index]['edit'] = true;
      data[index]["designation"] = { label: contactInfo.designation, value: contactInfo.designation };
      data[index]["category"] = { label: (contactInfo.category ? contactInfo.category.charAt(0).toUpperCase() + contactInfo.category.slice(1) : contactInfo.category), value: contactInfo.category };
      data[index]["status"] = { label: contactInfo.status, value: contactInfo.status };
      this.setState({ data, contactEdit: true });
    }
  };
  // -------------------------------------------------------------------- //

  /* ------------------------ Handle Form Submission ------------------------ */
  doSubmit = async () => {
    const { contact, selectedDesignation, selectedParent, selectedCategory } = this.state;

    if (selectedDesignation.value === null) {
      toast.error("Designation cannot be empty.");
      return;
    }

    if (selectedCategory.value === null && this.props.type == "introducer") {
      toast.error("Category cannot be empty.");
      return;
    }

    this.setState({ ajaxRequest: true });

    const { data } = await entity.createEntityContact(
      {
        first_name: contact.first_name,
        last_name: contact.last_name,
        designation: selectedDesignation.value,
        parent_id: selectedParent.value,
        email: contact.email,
        category: selectedCategory.value,
        phone_no: contact.phone,
        entity_id: this.props.entity,
      }
    );

    this.setState({ ajaxRequest: false });

    if (data.success === false) {
      toast.error(data.message);
    } else {
      toast.success(data.message);
      this.resetValues();
      this.fetchEntityContacts();
    }
  };
  // ---------------------------------------------------------------------- //

  // ----------- Async function to update contact details on the db ------------------- //
  UpdateContactDetails = async (contact_id) => {

    const contact = this.state.data.find(item => item.edit === true);

    if (contact) {

      if (contact.category.value === null && this.props.type == "introducer") {
        toast.error("Category cannot be empty.");
        return;
      }

      this.setState({ ajaxRequest: true });

      const { data } = await entity.createEntityContact(
        {
          contact_id: contact_id,
          first_name: contact.first_name,
          last_name: contact.last_name,
          designation: contact.designation.value,
          category: contact.category.value,
          email: contact.email,
          phone_no: contact.phone_no,
          entity_id: this.props.entity,
          status: contact.status.value,
        }
      );

      this.setState({ ajaxRequest: false });

      if (data.success === false) {
        toast.error(data.message);
      }
      else {
        toast.success(data.message);
        this.resetValues();
        this.setState({ contactEdit: false });
        this.fetchEntityContacts();
      }
    }
  };
  // -------------------------------------------------------------------- //

  // ---------------------- Handler to reset the values ---------------------- //
  resetValues = () => {
    this.setState({
      contact: {},
      selectedDesignation: { label: "Select designation", value: null },
      selectedParent: { label: "Select parent", value: null },
      selectedCategory: { label: "Select a Category", value: null },
    })
  }
  // -------------------------------------------------------------------- //

  onCloseClientInvitationModal = () => {
    this.setState({
      clientInvitationModal: false,
      selectedcontact: null,
    });
    this.fetchEntityContacts();
  };

  handleContactSearch = searchKey => {
    this.fetchEntityContacts(searchKey);
  }

  handleStatusChange = (selectedStatus) => {
    if (!selectedStatus || !selectedStatus.value) return;
    this.setState({ selectedStatus });
    this.fetchEntityContacts(null, selectedStatus.value);
  }; 

  // -------------------------- Deletion Handler -------------------------- //
  handleDeletion = (Item) => {

    this.props.handleEntityContactModal && this.props.handleEntityContactModal(false);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h4>Confirm to delete</h4>
            <p>{`Are you sure to delete this contact ?`}</p>
            <button
              className="btn btn-primary mr-2"
              onClick={() => {
                onClose();
                this.deleteItem(Item);
                this.props.handleEntityContactModal && this.props.handleEntityContactModal(false);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-white"
              onClick={() => {
                onClose();
                this.props.handleEntityContactModal && this.props.handleEntityContactModal(true);
              }}
            >
              No
            </button>
          </div>
        );
      },
      title: "Confirm to delete",
      message: "Are you sure to delete this contact ?",
    });

  };
  // ------------------------------------------------------------------------------ //

  handleInvitation = (item) => {
    if (item.phone_no == null || item.phone_no == "") {
      this.setState({ selectedcontact: item, showInvitation: false, clientInvitationModal: true });
    } else {
      this.setState({ selectedcontact: item, showInvitation: true, clientInvitationModal: true });
    }

  }

  render() {

    const { data, ajaxRequest, contact, categories, statuses } = this.state;
    const { constants, category } = auth.getCurrentUser();

    return (
      <React.Fragment>


         {/*-------------------------- Edit Team Member Modal ----------------------------*/}
         <Modal show={this.state.updateModal} size="lg" onHide={() => { this.setState({ updateModal: false }) }}   >
            <Modal.Header closeButton>
                <h6>Update Team Member</h6>
            </Modal.Header>

            <EditTeamMember
                data={this.state.selectedcontact}
                onCloseModal={() => {
                  this.setState({ updateModal: false })
                }}
                onHandleReload={this.fetchEntityContacts}
            ></EditTeamMember>
          </Modal>
          {/*------------------------------------------------------------------------------*/}

        {this.props.header != "profile" &&
          <Modal.Header closeButton>
            <h4 className="modal-title" >Manage Team Members</h4>
          </Modal.Header>
        }
        <Modal.Body>


          {/** -------------------------------- Client Invitation Modal -------------------------------- **/}
          <Modal show={this.state.clientInvitationModal} onHide={this.onCloseClientInvitationModal} size="lg"  >
            {
              this.state.showInvitation == true ? (
                <ContactInvitation
                  entityId={this.props.entity}
                  entityType={this.props.type} // bp or client
                  contactInfo={this.state.selectedcontact}
                  onClose={this.onCloseClientInvitationModal}
                />
              ) : (
                <>
                  <Modal.Header closeButton>
                    <h4 className="modal-title mb-4"></h4>

                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Phone number is required to sending OTP and notification. Please update phone number to sent invitation
                    </p>
                  </Modal.Body>
                </>
              )
            }
          </Modal>
          {/** ------------------------------------------------------------------------------------------ **/}

          {/** -------------------------------- PasswordReset Modal -------------------------------- **/}
          <Modal show={this.state.passwordResetModal} onHide={() => this.setState({ passwordResetModal: false })} size="sm"  >
            <PasswordReset
              clientEmail={this.state.selectedcontact}
              onClose={() => this.setState({ passwordResetModal: false })}
            />
          </Modal>

          {/** -------------------------------- Settings Modal -------------------------------- **/}
          <Modal show={this.state.settingsModal} onHide={() => this.setState({ settingsModal: false })} size="lg"  >
          <SettingsContact
              data={this.state.selectedcontact}
              onClose={() => this.setState({ settingsModal: false })}
              onHandleReload={this.fetchEntityContacts}
              type="entity"
            ></SettingsContact>
          </Modal>
          {/** ------------------------------------------------------------------------------------------ **/}

          <form onSubmit={this.handleSubmit}>

            <div className="form-group row">
              <div className="col-lg-6">
                <TextInput placeholder="Search team member" onChange={e => this.handleContactSearch(e.target.value)} type="normal" />
              </div>
              <div className="col-lg-6">
                <Picker 
                  items={statuses} selectedItem={this.state.selectedStatus} 
                  onSelectItem={selectedStatus => this.handleStatusChange(selectedStatus)}
                />
              </div>
            </div>

            <div className="my-3 table-responsive overflow-visible">
              <table class="table table-sm text-secondary">
                <thead>
                  <tr>
                    <th style={{ width: "15%" }}>First Name</th>
                    <th style={{ width: "15%" }}>Second Name</th>
                    <th style={{ width: "15%" }}>Designation</th>
                    {this.props.type == "introducer" && <th style={{ width: "10%" }}>Category</th>}
                    <th style={{ width: "15%" }}>Email</th>
                    <th style={{ width: "15%" }}>Phone</th>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "5%" }}>Primary</th>
                    <th style={{ width: "10%" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>


                  {data &&
                    data.map((item, index) => {
                      return (
                        <React.Fragment>
                          <tr key={index}>

                            <td>
                              {item.edit == false ? <span> {item.first_name} </span> :
                                <input name="first_name" type="text" placeholder="First Name" onChange={this.handleUpdateTextField} className="form-control input-sm" value={item.first_name} />
                              }
                            </td>

                            <td>
                              {item.edit == false ? <span> {item.last_name} </span> :
                                <input name="last_name" type="text" placeholder="Last Name" onChange={this.handleUpdateTextField} className="form-control input-sm" value={item.last_name} />
                              }
                            </td>

                            <td>
                              {item.edit == false ? item.designation :
                                <Picker items={contactDesignations} selectedItem={item.designation} onSelectItem={this.handleUpdateDesignation} />
                              }
                            </td>

                            {this.props.type == "introducer" &&
                              <td>
                                {item.edit == false ? (item.category && item.category.charAt(0).toUpperCase() + item.category.slice(1)) :
                                  <Picker items={categories} selectedItem={item.category} onSelectItem={this.handleUpdateCategory} />
                                }
                              </td>
                            }

                            <td>
                              {item.edit == false ? item.email :
                                <input name="email" type="text" placeholder="Email" onChange={this.handleUpdateTextField} className="form-control input-sm" value={item.email} readOnly={item.userLogin == true ? true : false} />
                              }
                            </td>

                            <td>
                              {item.edit == false ? item.phone_no :
                                <input name="phone_no" type="text" placeholder="Phone" onChange={this.handleUpdateTextField} className="form-control input-sm" value={item.phone_no} />
                              }
                            </td>

                            <td>
                              {item.edit == false ? (item.status && item.status.charAt(0).toUpperCase() + item.status.slice(1)) :
                                <Picker items={topHeaderAlert} selectedItem={item.status} onSelectItem={this.handleUpdateStatus} />
                              }
                            </td>
                            <td>
                              <div class="custom-control d-flex custom-checkbox">
                                <input
                                  id={item.contact_id}
                                  type="checkbox"
                                  className="custom-control-input"
                                  name={item.contact_id}
                                  checked={item.is_primary}
                                  onChange={(e) => this.handleCheckboxChange(e, "primary")}
                                />
                                <label class="custom-control-label" for={item.contact_id} />
                              </div>
                            </td>
                            <td className="no-top-border">

                                {item.edit == false ?
                                  <Link className="action-icons mr-1" style={{ ...this.styles.tableLink }} >
                                    {/* <EditButton onClick={() => this.handleEdit(item.contact_id, item)} /> */}
                                    <EditButton onClick={() => this.setState({ selectedcontact: item }, this.setState({ updateModal: true }))} />
                                  </Link> :

                                  <Link className="action-icons" style={{ ...this.styles.tableLink }} >
                                    <i alt="Save" data-toggle="tooltip" data-placement="left" title="Save" className="fa fa-save text-navy" onClick={(e) => { this.UpdateContactDetails(item.contact_id); }} />
                                  </Link>
                                }

                                {" "}

                                <Link className="action-icons mr-1" style={{ ...this.styles.tableLink }} >
                                  <DeleteButton onClick={(e) => this.handleDeletion(item.contact_id)} />
                                </Link>{" "}
                                < Link className="action-icons ml-2 " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                  <i className="fa fa-ellipsis-v"></i>
                                </Link>
                                <div className="dropdown-menu">
                                  {item.userLogin == false && (
                                      <Link className="dropdown-item action-icons" style={{ ...this.styles.tableLink }} onClick={(e) => this.handleInvitation(item)}>
                                          {`Invite ${this.props.type === 'bp' ? 'Partner' : (this.props.type == 'introducer' ? 'Introducer' : 'Client')} to join`}
                                      </Link>
                                  )}
                                  
                                  {
                                    item.userLogin == true && (
                                        <Link className="dropdown-item"
                                          onClick={
                                              event => {
                                              this.setState({ selectedcontact: item, passwordResetModal: true });
                                              }
                                          }
                                        >Reset Password
                                        </Link>
                                    ) 
                                  }

                                  <Link className="dropdown-item"  
                                  onClick={
                                    event => {
                                    this.setState({ selectedcontact: item, settingsModal: true  });
                                    }
                                }
                                >Settings</Link>
                              </div>

                                {/* {item.userLogin == false && (
                                  <Link className="action-icons" style={{ ...this.styles.tableLink }}>
                                    <i data-toggle="tooltip" data-placement="left" title={`Invite ${this.props.type === 'bp' ? 'Partner' : (this.props.type == 'introducer' ? 'Introducer' : 'Client')} to join`} className="fa fa fa-key"
                                      onClick={(e) => this.handleInvitation(item)}
                                    />
                                  </Link>
                                )}

                                {
                                  item.userLogin == true && (
                                    (category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) ? (
                                      <Link
                                        onClick={
                                          event => {
                                            this.setState({ selectedcontact: item, passwordResetModal: true });
                                          }
                                        }
                                      >
                                        <i title="Reset Password." class="fa fa-unlock text-secondary" />
                                      </Link>
                                    ) : (
                                      <i title="This user has already a login." class="fa fa-check text-secondary" />
                                    )

                                  )} */}
                             

                            </td>
                          </tr>

                        </React.Fragment>
                      );
                    })
                  }
                  {ajaxRequest && <div style={{ minHeight: 120 }}><LoadingSpinner /></div>}
                  {/* -------------------------- Inputs fields to Create a new Entity contact -------------------------- */}
                  {this.state.contactEdit == false &&
                    <tr>
                      <td>
                        <input name="first_name" value={contact.first_name ? contact.first_name : ''} type="text" placeholder="First Name" onChange={this.handleChange} className="form-control input-sm" />
                      </td>

                      <td  >
                        <input name="last_name" value={contact.last_name ? contact.last_name : ''} type="text" placeholder="Last Name" onChange={this.handleChange} className="form-control input-sm" />
                      </td>

                      <td>
                        <Picker items={contactDesignations} selectedItem={this.state.selectedDesignation} onSelectItem={selectedDesignation => this.setState({ selectedDesignation })} />
                      </td>

                      {this.props.type == "introducer" &&
                        <td >
                          <Picker items={categories} selectedItem={this.state.selectedCategory} onSelectItem={selectedCategory => this.setState({ selectedCategory })} />
                        </td>
                      }

                      <td  >
                        <input name="email" value={contact.email ? contact.email : ''} type="text" placeholder="Email" onChange={this.handleChange} className="form-control input-sm" />
                      </td>

                      <td  >
                        <input name="phone" value={contact.phone ? contact.phone : ''} type="text" placeholder="Phone" onChange={this.handleChange} className="form-control input-sm" />
                      </td>

                      <td></td>
                      <td></td>
                      <td>
                        <button className="btn btn-primary  " type="submit" >Add</button>
                        {/* <div style={{ width: 40 }}></div> */}
                      </td>
                    </tr>
                  }
                  {/*----------------------------------------------------------------------------------------------------*/}

                </tbody>
              </table>
            </div>

            <br></br>
          </form>
        </Modal.Body>

      </React.Fragment >
    );
  }
}
export default EntityContacts;