import React, { useState } from "react";
import { Picker } from '../../../../common/inputs';

import ManoovaIndex from "../directDebit/manoovaIndex";
import ManoovaTfIndex from "../directDebit/manoovaTfIndex";
import LoanDirectDebit from "../../../../loan/components/directDebit/index";
import { BreadCrumbs } from '../../../../common/layout';

const DirectDebitHome = () => {

    const facilities = [
        { label: "SCF Direct Debit", value: "SCF" },
        { label: "TF Direct Debit", value: "TF" },
        { label: "Loan Direct Debit", value: "LOAN" }
    ]

    const [selectedFacility, setSelectedFacility] = useState({ label: "SCF Direct Debit", value: "SCF" })

    return (
        <>

            <BreadCrumbs
                Header="Direct Debits"
                activeModule="Direct Debits"
                Route1="/dashboard"
                Label1="Dashboard"
            />

            <div className="card overflow-visible">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-8">
                            <h5>
                                {selectedFacility.label}
                            </h5>
                        </div>
                        <div className="col-lg-4">
                            <Picker
                                items={facilities}
                                selectedItem={selectedFacility}
                                onSelectItem={option => setSelectedFacility(option)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-2">
                {selectedFacility.value === "SCF" && <ManoovaIndex showBreadCrumbs={false} />}
                {selectedFacility.value === "TF" && <ManoovaTfIndex showBreadCrumbs={false} />}
                {selectedFacility.value === "LOAN" && <LoanDirectDebit showBreadCrumbs={false} />}
            </div>
        </>
    );
};

export default DirectDebitHome;