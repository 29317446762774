import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '../../../../common/layout';
import RequestPayment from './tfBatchPendingDd';
import PendingRequest from './tfBatchDirectDebit';
import PendingApproval from './tfBatchApprovalPayment';
import ScheduledPayment from './tfScheduledPayment';
import History from './tfBatchDirectDebit';
import UsersList from "../../../../externalapis/components/usersList";
import authService from "../../../../../services/authService";

function ManoovaTfIndex({ showBreadCrumbs = true }) {

    const { constants, category } = authService.getCurrentUser();

    const [activeTab, setActiveTab] = useState('RequestPayment');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const tabComponents = {
        RequestPayment: <RequestPayment changeTab={handleTabClick} />,
        PendingRequest: <PendingRequest paymentStatus="requested" />,
        History: <History paymentStatus="all" />,
        RegisteredUsers: <UsersList product={constants.PRODUCTS.TRADEFINANCE} />,
        PendingApproval: <PendingApproval paymentStatus="pending" />,
        // ScheduledPayment:<ScheduledPayment />,
    };

    return (
        <>
            <Helmet>
                <title>TF Direct Debit payments | {global.config.title}</title>
            </Helmet>

            {showBreadCrumbs &&
                <BreadCrumbs
                    Header="TF Direct Debit Payments"
                    activeModule="TF Direct Debit Payments"
                    Route1="/dashboard"
                    Label1="Dashboard"
                />
            }

            <div className="card">
                <div className="card-header ">
                    <div className="tabs-container">
                        <ul className="nav nav-tabs" role="tablist">
                            <li
                                className={activeTab === 'RequestPayment' ? 'nav-item active' : 'nav-item'}
                                onClick={() => handleTabClick('RequestPayment')}
                            >
                                <a className="nav-link">Request Payment</a>
                            </li>
                            <li
                                className={activeTab === 'PendingRequest' ? 'nav-item active' : 'nav-item'}
                                onClick={() => handleTabClick('PendingRequest')}
                            >
                                <a className="nav-link">Awaiting Payments</a>
                            </li>
                            <li
                                className={activeTab === 'PendingApproval' ? 'nav-item active' : 'nav-item'}
                                onClick={() => handleTabClick('PendingApproval')}
                            >
                                <a className="nav-link">Reconcile Payments</a>
                            </li>
                            <li
                                className={activeTab === 'History' ? 'nav-item active' : 'nav-item'}
                                onClick={() => handleTabClick('History')}
                            >
                                <a className="nav-link">History</a>
                            </li>
                            {/* <li
                                className={activeTab === 'ScheduledPayment' ? 'nav-item active' : 'nav-item'}
                                onClick={() => handleTabClick('ScheduledPayment')}
                            >
                                <a className="nav-link">Scheduled Payments</a>
                            </li> */}
                            {
                                constants.CATEGORY.COUNTRY_OFFICE === category && (
                                    <>
                                        <li
                                            className={activeTab === 'RegisteredUsers' ? 'nav-item active' : 'nav-item'}
                                            onClick={() => handleTabClick('RegisteredUsers')}
                                        >
                                            <a className="nav-link">Registered Accounts</a>
                                        </li>
                                    </>
                                )
                            }
                        </ul>
                    </div>

                </div>
                <div className="card-body">
                    {tabComponents[activeTab]}
                </div>
            </div>
        </>
    );
}

export default ManoovaTfIndex;
