import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { browserHistory } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Header from "./header";
import Footer from "./footer";
import "../../css/client.css";
import "../../../../styles/common.css";
import InitialLoader from "../../components/cards/_initialLoader";
import QuickLaunch from "../main/quickLaunch";
import InvCreation from "../../pages/ifTransactions/_createInvoice";
import RoleChecker from "../../../../utils/roleChecker";
import CreateInvvoiceCustomer from "../../../supply-chain/components/transactions/customerUpload/index";
import CreateInvoice from "../../../supply-chain/components/transactions/supplierUpload/index";
import NoAccess from "../../widgets/noAccess";
import EntityContext from "../../../../context/entityContext";
import NotificationContext from "../../../../context/notificationContext";
import entityService from "../../../../services/entityService";
import authService from "../../../../services/authService";
import dashboardServices from "../../../../services/dashboardServices";
import transactionService from "../../../../components/supply-chain/services/transaction_service";
import NewTrader from "../../pages/scTransactions/tradersManagement/newTrader";
import ErrorBoundary from '../../../common/ErrorBoundary';
import CreateTfInvoiceCustomer from "../../../supply-chain/components/transactions/customerUpload/tfAddInvoice";
import CreateTfInvoice from "../../../supply-chain/components/transactions/supplierUpload/tfAddSupplierInvoice";
import CreateGenaralTransaction from "../../components/generalTransaction/_createTransaction";
import * as moment from "moment";
class ClientPortalLayout extends Component {

  constructor() {
    super();
    this.state = {
      authenticated: false,
      data: null,
      settings: null,
      loading: false,
      selectedProduct: null,
      selectedBusinessType: null,
      businessTypes: [],
      selectedRow: [],
      invTransModal: false,
      openModal: false,
      openModalCustomer: false,
      noAccess: false,
      primaryFacilities: false,
      salesRoles: [],
      purchaseRoles: [],
      addSupplier: false,
      tfSupplierAdd: false,
      tfCustomerAdd: false,
      accountDetails: [],
      notifications: [],
      notificationPlayed: false,
      notificationLoader: false,
      terminology: null,
      addGenTrans: false,
      specialProgramEnabledTF: false,
      specialProgramEnabledSCF: false
    };
  }

  componentDidMount() {

    if (!authService.validateJWTToken()) {
      authService.logout();
      browserHistory.push("/client/login");
    }
    else {

      this.setState({ authenticated: true }, () => {
        this.populateClientDetails();
      });

      // const { is_primary } = authService.getCurrentUser();
      // const script1 = document.createElement('script');
      // script1.innerHTML = `window.intercomSettings = { 
      //   api_base: 'https://api-iam.intercom.io', 
      //   app_id: 'i4333cxx', 
      //   loggedUser: ${is_primary ? "'fifoPortalPrimary'" : "'fifoPortalSecondary'"}  
      // };`;
      // document.body.appendChild(script1);

      // const script2 = document.createElement('script');
      // script2.innerHTML = "(function(){var w=window;var ic=w.Intercom;if(typeof ic==='function'){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/i4333cxx';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();";
      // document.body.appendChild(script2);
    }

  }

  populateClientDetails = async (selectedOrganisation = null) => {

    if (authService.getCurrentUser() && this.state.authenticated === true) {
      const { last_logged_in_entity, constants } = authService.getCurrentUser();
      this.setState({ loading: true });
      const response = await entityService.getEntityDetails(
        selectedOrganisation === null
          ? last_logged_in_entity
          : selectedOrganisation.value
      );

      this.setState({
        data: response.rows,
        settings: response.settings,
        terminology: response.rows.terminology
      });

      // ------------------------------- Hubspot Chat Script ------------------------------- //
      if(response.rows.owner.business_type === 'Corporate'){
        const script = document.createElement('script');
        script.src = "//js-na1.hs-scripts.com/5367608.js";
        script.async = true;
        script.defer = true;
        script.id = "hs-script-loader";

        // Append the script to the body or head of the document
        document.body.appendChild(script);

        // Optionally, you can listen for the load event to know when the script is fully loaded
        // script.onload = () => {
        //   console.log('Script loaded successfully!');
        // };
      }
      // ----------------------------------------------------------------------------------- //

      let businessTypes = [];
      //--------------Enabled for any primary------------------------------------------------
      const activeRoles = RoleChecker.checkRoleCategory(response.rows.products, constants.PRIMARY_ROLES, constants.SECONDARY_ROLES);

      if (activeRoles.primary) {
        //this.setState({ selectedProduct: "ALL" });
        //this.setState({ selectedBusinessType: constants.BUSINESS_TYPE.PURCHASE });
        this.setState({ primaryFacilities: activeRoles.primary });
      }
      else {
        //-------------Only Secondary roles---------------------
        //this.setState({ selectedProduct: 5 });
        //this.setState({ selectedBusinessType: constants.BUSINESS_TYPE.SALE });
      }
      //----------------------------Identifying sales roles----------------------------------------------
      let salesRoles = [];
      let purchaseRoles = [];

      let specialProgramEnabledSCF = false;
      let specialProgramEnabledTF = false;
      {/*------------------------ Primary Roles ------------------------ */ }
      let facilityNumber = 0;

      if (RoleChecker.check(response.rows.products, constants.ROLES.CUSTOMER)) {
        facilityNumber += 1;
        purchaseRoles.push({
          value: constants.BUSINESS_TYPE.PURCHASE,
          product: constants.PRODUCTS.SUPPLYCHAIN,
          facilityNumber: facilityNumber,
          productName: 'SCF',

          nick_name: 'Accounts Payable',
          name: `Accounts Payable (Facility ${facilityNumber})`
        });

        // check if any special programs are enabled for the SCF Customer
        const specialProgram = await transactionService.CheckCustomerSpecialProgramEnabled({ product: constants.PRODUCTS.SUPPLYCHAIN });
        specialProgramEnabledSCF = specialProgram.data.result;
      }

      if (RoleChecker.check(response.rows.products, constants.ROLES.CLIENT)) {
        facilityNumber += 1;
        salesRoles.push({
          value: constants.BUSINESS_TYPE.SALE,
          product: constants.PRODUCTS.INVOICE,
          facilityNumber: facilityNumber,
          productName: 'Invoices Finance',

          nick_name: 'Accounts Receivable',
          name: `Accounts Receivable (Facility ${facilityNumber})`
        });
      }

      if (RoleChecker.check(response.rows.products, constants.ROLES.TFCUSTOMER)) {
        facilityNumber += 1;
        purchaseRoles.push({
          value: constants.BUSINESS_TYPE.PURCHASE,
          product: constants.PRODUCTS.TRADEFINANCE,
          facilityNumber: facilityNumber,
          productName: 'TF',

          nick_name: 'Accounts Payable',
          name: `Accounts Payable (Facility ${facilityNumber})`
        });

        // check if any special programs are enabled for the SCF Customer
        const specialProgram = await transactionService.CheckCustomerSpecialProgramEnabled({ product: constants.PRODUCTS.TRADEFINANCE });
        specialProgramEnabledTF = specialProgram.data.result;
      }

      if (RoleChecker.check(response.rows.products, constants.ROLES.DFCUSTOMER)) {
        facilityNumber += 1;
        salesRoles.push({
          value: constants.BUSINESS_TYPE.SALE,
          product: constants.PRODUCTS.DEBTORFINANACE,
          facilityNumber: facilityNumber,
          productName: 'Whole of Book',

          nick_name: 'Accounts Receivable',
          name: `Accounts Receivable (Facility ${facilityNumber})`
        });
      }

      if (RoleChecker.check(response.rows.products, constants.ROLES.LOANCLIENT)) {
        facilityNumber += 1;
        salesRoles.push({
          value: constants.BUSINESS_TYPE.SALE,
          product: constants.PRODUCTS.LOAN,
          facilityNumber: facilityNumber,
          productName: 'Business Loan',

          nick_name: 'Business Loans',
          name: `Business Loans (Facility ${facilityNumber})`
        });
      }

      {/*------------------------ Secondary Roles ------------------------ */ }
      if (RoleChecker.check(response.rows.products, constants.ROLES.DEBTOR)) {
        purchaseRoles.push({
          name: "Supplier Financed Invoices",
          nick_name: "Supplier Financed Invoices",
          value: constants.BUSINESS_TYPE.PURCHASE,
          product: constants.PRODUCTS.INVOICE,
        });
      }

      if (RoleChecker.check(response.rows.products, constants.ROLES.DFDEBTOR)) {
        purchaseRoles.push({
          name: "Whole of Book",
          nick_name: "Whole of Book",
          value: constants.BUSINESS_TYPE.PURCHASE,
          product: constants.PRODUCTS.DEBTORFINANACE,
        });
      }

      if (RoleChecker.check(response.rows.products, constants.ROLES.SUPPLIER) || RoleChecker.check(response.rows.products, constants.ROLES.TFSUPPLIER)) {
        salesRoles.push({
          name: "Customer Programs",
          nick_name: "Customer Programs",
          value: constants.BUSINESS_TYPE.SALE,
          product: constants.PRODUCTS.CUSTOMERPROGRAMS,
          roles: {
            [constants.PRODUCTS.TRADEFINANCE]: RoleChecker.check(response.rows.products, constants.ROLES.TFSUPPLIER),
            [constants.PRODUCTS.SUPPLYCHAIN]: RoleChecker.check(response.rows.products, constants.ROLES.SUPPLIER)
          }
        });
      }

      //--------------------------------------------------------------------------------------------------
      const productTypes = [];

      if (
        RoleChecker.check(response.rows.products, constants.ROLES.DFCUSTOMER) ||
        RoleChecker.check(response.rows.products, constants.ROLES.CLIENT) ||
        RoleChecker.check(response.rows.products, constants.ROLES.SUPPLIER) ||
        RoleChecker.check(response.rows.products, constants.ROLES.TFSUPPLIER)
      ) {
        businessTypes.push({
          name: "Early Payment Invoices",
          value: constants.BUSINESS_TYPE.SALE,
        });
      }

      if (
        RoleChecker.check(response.rows.products, constants.ROLES.CUSTOMER) ||
        RoleChecker.check(response.rows.products, constants.ROLES.DEBTOR) ||
        RoleChecker.check(response.rows.products, constants.ROLES.DFDEBTOR) ||
        RoleChecker.check(response.rows.products, constants.ROLES.TFCUSTOMER)
      ) {
        businessTypes.push({
          name: "Purchase Invoices",
          value: constants.BUSINESS_TYPE.PURCHASE,
        });
      }

      if (
        RoleChecker.check(response.rows.products, constants.ROLES.CUSTOMER) ||
        RoleChecker.check(response.rows.products, constants.ROLES.SUPPLIER)
      ) {
        productTypes.push({
          name: "Supply Chain Finance",
          value: constants.PRODUCTS.SUPPLYCHAIN,
        });
      }

      if (
        RoleChecker.check(response.rows.products, constants.ROLES.TFCUSTOMER) ||
        RoleChecker.check(response.rows.products, constants.ROLES.TFSUPPLIER)
      ) {
        productTypes.push({
          name: "Trade Finance",
          value: constants.PRODUCTS.TRADEFINANCE,
        });
      }

      if (
        RoleChecker.check(response.rows.products, constants.ROLES.CLIENT) ||
        RoleChecker.check(response.rows.products, constants.ROLES.DFDEBTOR)
      ) {
        productTypes.push({
          name: "Invoice Finance",
          value: constants.PRODUCTS.INVOICE,
        });
      }

      if (
        RoleChecker.check(response.rows.products, constants.ROLES.DFCUSTOMER) ||
        RoleChecker.check(response.rows.products, constants.ROLES.DEBTOR)
      ) {
        productTypes.push({
          name: "Debtor Finance Transactions",
          value: constants.PRODUCTS.DEBTORFINANACE,
        });
      }

      if (RoleChecker.check(response.rows.products, constants.ROLES.LOANCLIENT)) {
        productTypes.push({
          name: "Business Loan",
          value: constants.PRODUCTS.LOAN,
        });
      }

      this.setState({
        businessTypes,
        salesRoles,
        purchaseRoles,
        productTypes,
        specialProgramEnabledTF,
        specialProgramEnabledSCF,
        loading: false
      }, () => {

        if (activeRoles.primary)
          this.fetchBankAccountDetails();

        this.fetchnotifications(selectedOrganisation === null
          ? last_logged_in_entity
          : selectedOrganisation.value);
      });
      // -------------------------------------------------------------------------------------------- //
    }
  };

  fetchBankAccountDetails = async () => {
    this.setState({ loading: true });
    const result = await entityService.FetchAllproductBankdetail({ clientSide: true });
    this.setState({ loading: false });
    if (result.data && result.data.response !== undefined) {
      this.setState({ accountDetails: result.data.response });

      result.data.response.map(item => {
        this.UpdateFacilityAccountName(item);
      })
    }
  }

  playAudio() {
    const audioPromise = this.audio.play()
    if (audioPromise !== undefined) {
      audioPromise
        .then(_ => {
          // autoplay started
        })
        .catch(err => {
          // catch dom exception
          console.info(err)
        })
    }
  }

  fetchnotifications = async (a_guid) => {
    const user = authService.getCurrentUser();
    if (user) {

      // Finds the difference in minutes between the last logged in time and the current time (UTC)
      let playNotification = true;

      if (user.last_logged_in_time) {
        let currentUTC = new Date(new Date().toUTCString().slice(0, -3));
        let now = moment(currentUTC);
        let then = moment(user.last_logged_in_time);
        let minutes = now.diff(then, 'minutes');

        if (minutes < 15)
          playNotification = false;
      }
      // ----------------------------------------- //

      this.setState({ notificationLoader: true });
      const rows = await dashboardServices.getNotifications(a_guid);
      this.setState({ notificationLoader: false });
      if (rows) {
        this.setState({ notifications: rows.notifications });

        let notificationCount = rows.notifications.length;
        let audioFile = null;

        if (notificationCount === 1)
          audioFile = '1_unread_notification';
        else if (notificationCount === 2)
          audioFile = '2_unread_notifications';
        else if (notificationCount >= 3) //3 && notificationCount <= 9)
          audioFile = 'a_few_unread_notifications';
        //else if(notificationCount >= 10)
        //  audioFile = 'over_10_notifications';

        if (audioFile && !this.state.notificationPlayed && playNotification) {
          this.audio = new Audio(`/audios/${audioFile}.m4a`);
          this.audio.load();
          this.playAudio();
          this.setState({ notificationPlayed: true })
        }
      }
    }
  };

  UpdateFacilityAccountName = accDetails => {

    const { constants } = authService.getCurrentUser();

    const purchaseArr = this.state.purchaseRoles.map(obj => {
      if (obj.product === parseInt(accDetails.product) && obj.facilityNumber) {
        obj.nick_name = accDetails.nick_name ? accDetails.nick_name : accDetails.bp_facility_nickname ? accDetails.bp_facility_nickname : 'Accounts Payable'
        obj.name = `${obj.nick_name} (Facility ${obj.facilityNumber})`
      }
      return obj;
    });

    const salesArr = this.state.salesRoles.map(obj => {
      if (obj.product === parseInt(accDetails.product) && obj.facilityNumber) {

        if (obj.product === constants.PRODUCTS.LOAN)
          obj.nick_name = accDetails.nick_name ? accDetails.nick_name : accDetails.bp_facility_nickname ? accDetails.bp_facility_nickname : 'Business Loans'
        else
          obj.nick_name = accDetails.nick_name ? accDetails.nick_name : accDetails.bp_facility_nickname ? accDetails.bp_facility_nickname : 'Accounts Receivable'

        obj.name = `${obj.nick_name} (Facility ${obj.facilityNumber})`
      }
      return obj;
    });

    // ------------------------------------------ //
    const accounts = [...this.state.accountDetails];
    const entryIndex = this.state.accountDetails.findIndex(
      item => item.product == accDetails.product
    );

    if (entryIndex !== -1)
      accounts[entryIndex] = accDetails;
    else
      accounts.push(accDetails);
    // ------------------------------------------ //
    this.setState({
      purchaseRoles: purchaseArr,
      salesRoles: salesArr,
      accountDetails: accounts
    });

  }

  setBusinessTypes = (types) => {
    this.setState({ businessTypes: types });
  };

  handleProductChange = (selectedProduct) => {
    this.setState({ selectedProduct });
  };

  handleRoleChange = (selectedBusinessType) => {
    this.setState({ selectedBusinessType });
  };

  handleInvTransModal = (isSet) => {
    this.setState({ invTransModal: isSet });
  };

  handleGenaralInvModal = (isSet) => {
    this.setState({ addGenTrans: isSet });
  };

  handleSCInvoiceModal = (UserType, open) => {
    if (open) {
      if (UserType == 'supplier') {
        this.setState({ openModal: true });
      } else if (UserType == 'customer') {
        this.setState({ openModalCustomer: true });
      }
      else {
        this.setState({ noAccess: true });
      }
    }
  };
  handleTfInvoiceModal = (UserType, open) => {

    if (open) {
      if (UserType == 'supplier') {
        this.setState({ tfSupplierAdd: true });
      } else if (UserType == 'customer') {
        this.setState({ tfCustomerAdd: true });
      }
      else {
        this.setState({ noAccess: true });
      }
    }
  };

  openInvoiceModal = (product, selectedBusinessType) => {
    const { products, constants } = authService.getCurrentUser();

    if (product === constants.PRODUCTS.INVOICE)
      this.handleInvTransModal(true);

    else if (product === constants.PRODUCTS.SUPPLYCHAIN) {

      if ((selectedBusinessType == constants.BUSINESS_TYPE.PURCHASE) && (RoleChecker.check(products, constants.ROLES.CUSTOMER))) {
        this.handleSCInvoiceModal("customer", true);
      }
      else if ((selectedBusinessType == constants.BUSINESS_TYPE.SALE) && (RoleChecker.check(products, constants.ROLES.SUPPLIER))) {
        this.handleSCInvoiceModal("supplier", true);
      }
      else {
        this.handleSCInvoiceModal("noaccess", true);
      }
      // if (
      //   RoleChecker.check(products, constants.ROLES.CUSTOMER) &&
      //   RoleChecker.check(products, constants.ROLES.SUPPLIER)
      // ) {
      //   this.handleSCInvoiceModal("customer", true);
      // } else if (RoleChecker.check(products, constants.ROLES.CUSTOMER)) {
      //   this.handleSCInvoiceModal("customer", true);
      // } else if (RoleChecker.check(products, constants.ROLES.SUPPLIER) ) {
      //   this.handleSCInvoiceModal("supplier", true);
      // } else {
      //   this.handleSCInvoiceModal("noaccess", true);
      // }
    }
    else if (product === constants.PRODUCTS.TRADEFINANCE) {
      if ((selectedBusinessType == constants.BUSINESS_TYPE.PURCHASE) && (RoleChecker.check(products, constants.ROLES.TFCUSTOMER))) {
        this.handleTfInvoiceModal("customer", true);
      }
      else if ((selectedBusinessType == constants.BUSINESS_TYPE.SALE) && (RoleChecker.check(products, constants.ROLES.TFSUPPLIER))) {
        this.handleTfInvoiceModal("supplier", true);
      }
      else {
        this.handleTfInvoiceModal("noaccess", true);
      }
      // if (
      //   RoleChecker.check(products, constants.ROLES.TFCUSTOMER) &&
      //   RoleChecker.check(products, constants.ROLES.TFSUPPLIER)
      // ) {
      //   this.handleTfInvoiceModal("customer", true);
      // } else if (RoleChecker.check(products, constants.ROLES.TFCUSTOMER)) {
      //   this.handleTfInvoiceModal("customer", true);
      // } else if (RoleChecker.check(products, constants.ROLES.TFSUPPLIER)) {
      //   this.handleTfInvoiceModal("supplier", true);
      // } else {
      //   this.handleTfInvoiceModal("noaccess", true);
      // }
    }
    else if (product === constants.PRODUCTS.CUSTOMERPROGRAMS) {
      this.handleGenaralInvModal(true);
    }
  }
  openAddSupplier = () => this.setState({ addSupplier: true });
  render() {
    return (
      <>
        <EntityContext.Provider
          value={{
            populateClientDetails: this.populateClientDetails,
            entityDetails: this.state.data,
            entitySettings: this.state.settings,
            handleProductChange: this.handleProductChange,
            handleRoleChange: this.handleRoleChange,
            selProduct: this.state.selectedProduct,
            selectedBusinessType: this.state.selectedBusinessType,
            businessTypes: this.state.businessTypes,
            setBusinessTypes: this.setBusinessTypes,
            productTypes: this.state.productTypes,
            primaryFacilities: this.state.primaryFacilities,
            salesRoles: this.state.salesRoles,
            purchaseRoles: this.state.purchaseRoles,
            loader: this.state.loading,
            openInvoiceModal: this.openInvoiceModal,
            openAddSupplier: this.openAddSupplier,
            UpdateFacilityAccountName: this.UpdateFacilityAccountName,
            accountDetails: this.state.accountDetails,
            terminology: this.state.terminology,
            specialProgramEnabledTF: this.state.specialProgramEnabledTF,
            specialProgramEnabledSCF: this.state.specialProgramEnabledSCF
          }}
        >
          <NotificationContext.Provider value={{ notifications: this.state.notifications, fetchnotifications: this.fetchnotifications, notificationLoader: this.state.notificationLoader }}>
            {this.state.authenticated === true && (

              <>
                <ErrorBoundary from="client">
                  {/*------------------Start Transaction Modal-------------------*/}
                  <Modal
                    show={this.state.invTransModal}
                    onHide={() => this.setState({ invTransModal: false })}
                    centered
                    backdrop='static'
                  >
                    <InvCreation
                      onClose={() => this.setState({ invTransModal: false })}
                    />
                  </Modal>
                  {/*-------------------------------------------------------------*/}


                  {/*------------------Start Supply chain Modal-------------------*/}
                  <Modal
                    show={this.state.openModalCustomer}
                    onHide={() => { this.setState({ openModalCustomer: false }) }}
                    backdrop="static"
                    centered
                  >
                    <CreateInvvoiceCustomer
                      a_guid={this.state.data ? this.state.data.a_guid : ""}
                      page="common"
                      onCloseModal={() => { this.setState({ openModalCustomer: false }) }}
                    ></CreateInvvoiceCustomer>
                  </Modal>

                  <Modal
                    show={this.state.openModal}
                    onHide={() => { this.setState({ openModal: false }) }}
                    backdrop="static"
                    centered
                  >
                    <CreateInvoice
                      a_guid={this.state.data ? this.state.data.a_guid : ""}
                      page="common"
                      onCloseModal={() => { this.setState({ openModal: false }) }}
                    ></CreateInvoice>
                  </Modal>

                  {/*================================TF INVOICE CREATION=======================================*/}
                  <Modal
                    show={this.state.tfCustomerAdd}
                    onHide={() => { this.setState({ tfCustomerAdd: false }) }}
                    backdrop="static"
                    centered
                  >
                    <CreateTfInvoiceCustomer
                      a_guid={this.state.data ? this.state.data.a_guid : ""}
                      page="common"
                      onCloseModal={() => { this.setState({ tfCustomerAdd: false }) }}
                    />
                  </Modal>

                  <Modal
                    show={this.state.tfSupplierAdd}
                    onHide={() => { this.setState({ tfSupplierAdd: false }) }}
                    backdrop="static"
                    centered
                  >
                    <CreateTfInvoice
                      a_guid={this.state.data ? this.state.data.a_guid : ""}
                      page="common"
                      onCloseModal={() => { this.setState({ tfSupplierAdd: false }) }}
                    />
                  </Modal>
                  {/*================================END TF INVOICE CREATION=======================================*/}
                  <Modal
                    show={this.state.noAccess}
                    onHide={() => { this.setState({ noAccess: false }) }}
                    backdrop="static"
                    size={'xl'}
                    centered
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <NoAccess />
                  </Modal>

                  <Modal
                    show={this.state.addSupplier}
                    onHide={() => this.setState({ addSupplier: false })}
                    centered
                    backdrop="static"
                    size="lg"
                  >
                    <NewTrader
                      entity_id={this.state.data && this.state.data.a_guid}
                      role={this.state.selectedBusinessType}
                      title="Supplier"
                      source="network"
                      onCloseModal={() => this.setState({ addSupplier: false })}
                    />
                  </Modal>

                  {/*================================GENERAL INVOICE CREATION=======================================*/}

                  <Modal
                    show={this.state.addGenTrans}
                    onHide={() => this.setState({ addGenTrans: false })}
                    backdrop="static"
                    centered
                  >
                    <CreateGenaralTransaction
                      onCloseModal={() => this.setState({ addGenTrans: false })}
                      selectUserType="secondary_accounts"
                      dropDownHeading="Select Customer"
                      initiated_user_type="supplier"
                      invoice_type="payable"
                      refreshCard={() => { }}
                    />

                  </Modal>

                  {/*================================GENERAL INVOICE CREATION=======================================*/}

                  {/*================================Client Layout Starts=======================================*/}
                  <div id="client-wrap" className="bg-light bg-img">
                    <div className="position-fixed fixed-bottom right-0 mb-5 mr-4 floating-btn left-auto">
                      <div className="dropup">
                        <QuickLaunch
                          openInvoiceModal={this.openInvoiceModal}
                        />
                      </div>
                    </div>
                    {/*------------------------------------------------------------------------------------*/}
                    <Header
                      activePath={this.props.location.pathname.substring(
                        this.props.location.pathname
                      )}
                      location={this.props.location}
                    />
                    <ToastContainer />

                    <div className="row"></div>

                    <div className="container-fluid">
                      <div className="container pt-3">
                        {/*this.state.selectedProduct !== "ALL" && !this.state.loading && <FifoTabs />*/}
                        {this.state.data !== null && !this.state.loading && this.props.children}
                        {this.state.loading && <InitialLoader />}
                      </div>
                    </div>
                  </div>
                  <Footer></Footer>
                  {/*================================Client Layout Ends=======================================*/}
                </ErrorBoundary>
              </>
            )}
          </NotificationContext.Provider>
        </EntityContext.Provider>
      </>
    );
  }
}

export default ClientPortalLayout;
