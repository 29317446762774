import React from "react";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Modal from 'react-bootstrap/Modal';
import InvIcon from "../../../../../img/invoice.svg";
import Form from "../../../../common/Form";
import { FormRow, FormColumnLg, TermsConditions } from "../../../../common/containers/formContainer";
import { SaveButton, AddButton, ModalCloseButton } from "../../../../common/containers/button";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import { TextInput } from "../../../../common/inputs/textInput/textInput";
import { DateTimePicker } from "../../../../common/inputs/datePicker";
import { CurrencyText, } from "../../../../common/inputs/currencyInput/currencyInput";
import MultipleFileInput from "../../../../common/inputs/multipleFileInput/multipleFileInput";
import Confirmation from "./_confirmation";
import Transaction from "../../../services/transaction_service";
import auth from "../../../../../services/authService";
import UserType from "../../../hooks/useUserType";
import EntityContext from "../../../../../context/entityContext";
import Utility from "../../../utils/DateParser";
import RoleChecker from "../../../../../utils/roleChecker";
import AmountInfo from "./amountInfo";
import CurrencyDetails from "../invoiceview/_currencyDetails";
import { confirmAlert } from "react-confirm-alert";
import { browserHistory, Link } from "react-router";
import commonService from "../../../services/commonService";
import ReactTooltip from 'react-tooltip';
import { GeneralButton } from "../../../../common/inputs";
import { knowledgeBaseUrls } from "../../../../../config/predefinedValues.json"
class TfCreateInvoiceCustomer extends Form {

  static contextType = EntityContext;
  constructor(props) {
    super(props);
    this.confirmationRef = React.createRef();
    this.invRef = React.createRef();
  }

  state = {
    data: {
      trans_guid: "",
      supplier: "",
      customer: this.props.a_guid ? this.props.a_guid : "",
      trans_date: null,
      amount: 0,
      inv_due_date: null,
      invoice_number: "",
      purchase_order_no: "",
      document_name: "",
      inv_revised_due_date: null,
      errors: {},
      fee: "",
      disabled: false,
      confirmation: false,
      rate_guid: "",
      revisedLoading: false,
      extension_field: '',
      currencyStatus: "inactive",
      currency: { label: "AUD", value: "A$" },
      exchangeRate: 1,
      fcyAmount: "",
      pay_supplier: false,
      pay_supplier_date: null,
      other_programs: 'no'
    },

    files: [],
    ajaxRequest: false,
    disabled: false,
    products: [],
    constants: [],
    loading: false,
    readOnly: true,
  };

  schema = Joi.object()
    .keys({
      supplier: Joi.string().required().label("Supplier").error((errors) => { return { message: "Please select a Supplier" }; }),
      invoice_number: Joi.string().required().error((errors) => { return { message: "Please enter a valid Invoice Number" }; }),
      amount: Joi.number().min(1).required().label("Amount").error((errors) => { return { message: "Please enter the invoice amount" }; }),
      trans_date: Joi.date().required().error((errors) => { return { message: "Please enter a valid Invoice Date" }; }),
      inv_due_date: Joi.date()
        .required()
        .min(Joi.ref("trans_date"))
        .label("Invoice Due Date").error((errors) => { return { message: "Please select a date for the Original Due Date" }; }),
    })
    .unknown(true);


  validate = () => {
    const options = { abortEarly: true };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
      toast.error(item.message);
      this.focusInput(item.path[0]);
    }
    return errors;
  };

  doSubmit = async () => {
    const { category, constants } = auth.getCurrentUser();
    const { ParseDate } = Utility(); // Instance for utility library.
    this.setState({ ajaxRequest: true, disabled: true });
    try {

      const { data } = this.state;
      let data1;
      if (this.props.a_guid) {
        data["customer"] = this.props.a_guid;
      }

      if (data.inv_due_date != null)
        data["inv_due_date"] = ParseDate(data.inv_due_date);

      if (data.inv_revised_due_date != null)
        data["inv_revised_due_date"] = ParseDate(data.inv_revised_due_date);

      if (data.pay_supplier_date != null)
        data["pay_supplier_date"] = ParseDate(data.pay_supplier_date);

      const formatedInvDateString = ParseDate(data.trans_date);
      data["trans_date"] = formatedInvDateString;

      let fileslist = [];
      if (!data.trans_guid) {
        fileslist = await this.handleFileReader();
      }

      if (data.currencyStatus == "inactive")
        data["currency"] = "AUD";
      else {

        data["currency"] = (data.currency && data.currency != null && data.currency != "undefined") ? data.currency.label : "AUD";
        data["fcyAmount"] = data.exchangeRate ? data.exchangeRate : 0;

        if (data.amount && data.exchangeRate)
          data["fcyAmount"] = data.amount * data.exchangeRate;

      }
      const errors = this.validate();
      this.setState({ errors: errors || {} });


      if (errors) {
        data["confirmation"] = false;
        this.setState({ data, ajaxRequest: false, disabled: false }); return;
      } else {
        const confirmationRefState = this.confirmationRef.current;
        const errors = confirmationRefState.validate();
        if (errors) { this.setState({ ajaxRequest: false, disabled: false }); return; }
        else {
          data["product_type"] = this.state.constants.PRODUCTS.TRADEFINANCE;
          data1 = { ...data, ...confirmationRefState.state.data }
        }
      }

      
      const { success, message, inv_guid, interaction, type, status_code, status } = await Transaction.createInvoice(data1, fileslist, "customer", this.props.type === "convert" ? this.props.converting_trans_guid : null);
      if (success === false) {
        toast.error(message);
      } else {
        if (this.props.type === "convert")
          this.props.onCloseModal(inv_guid);
        else {
          if (category === constants.CATEGORY.TRADERS && type == "add") {
            this.props.onCloseModal();
            if (this.props.onSuccessCreation) {
              this.props.onSuccessCreation();
            }
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="react-confirm-alert">
                    <div className="custom-delete-ui">
                      <h4 className="mb-3">New Invoice Has Been Added</h4>
                      <div className="text-xs">{interaction == 'disclosed' ? "You'll find the invoice in the Awaiting Supplier Update section" : "You'll find the invoice in the Pending Supplier Payment"}</div>
                      <div className="text-light   text-center text-xs mt-1">{interaction == 'disclosed' ? 'Your supplier has been notified they can ask for early payment' : 'Offer you supplier early payment to offset the cost of finance'}</div>


                      <button className="btn btn-white mt-3" onClick={() => onClose()}>Close</button>
                      <button className="btn btn-primary ml-2 mt-3" onClick={() => {
                        browserHistory.push({ pathname: '/client/tf/purchase-invoices', state: status });
                        onClose()
                      }} >
                        {interaction == 'disclosed' ? 'Awaiting Supplier Update Section' : 'Pending Supplier Payment Section'}
                      </button>
                      <button className="btn btn-primary ml-2 mt-3" onClick={() => {
                        browserHistory.push({ pathname: '/client/tf/invoice/details', search: "?inv_guid=" + inv_guid });
                        onClose()
                      }} >
                        View Invoice
                      </button>
                      <div className="mt-4 text-secondary text-center">
                        {interaction == 'disclosed' ?
                          <a className="text-primary ml-2 cursor-link"
                            onClick={async () => {
                              window.open(knowledgeBaseUrls['early_payment'], '_blank');
                            }} >
                            Learn More About Early Payment
                          </a> :
                          <>
                            <a className="text-primary ml-2 mt-2 cursor-link"
                              onClick={async () => {
                                window.open(knowledgeBaseUrls['early_payment'], '_blank');
                              }} >
                              How do I offset the cost of finance
                            </a>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                );
              },
            });
          } else if (type == "edit") {
            this.props.onCloseModal();
            if (this.props.onSuccessCreation) {
              this.props.onSuccessCreation();
            }
            toast.success(message);
          } else {
            toast.success(message);
            browserHistory.push({ pathname: '/tfinvoice/details', search: "?inv_guid=" + inv_guid });
          }
        }
      }
      this.setState({ ajaxRequest: false, disabled: false });
    } catch (error) {
      console.log(error);
    }
  };
  //-----------Submit invoice-----------------------
  handleSubmit = (e) => { e.preventDefault(); this.doSubmit(); };

  confirmation = async () => {
    const data = { ...this.state.data };
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      data["confirmation"] = false;
      this.setState({ data });
      this.setState({ ajaxRequest: false, disabled: false });
      return true;
    } else {
      if (!data.trans_guid || (data.trans_guid && data.invoice_number_old != data.invoice_number)) {
        const { data: res } = await commonService.checkDuplicateInvNumber({ "inv_numbers": [data.invoice_number], "suppliers" : [data.supplier] });
        if (res.success) {
          toast.error("Invoice Number has already exist in the System!");
          return true;
        }
      }
      data["confirmation"] = true;
      this.setState({ data });
    }
  };

  chnageConfirmation = async () => {
    const data = { ...this.state.data };
    data["confirmation"] = false;
    this.setState({ data });
  };
  onCloseModal = () => {
    this.setState({ open: false, selectedFeeType: [] });
  };
  setValues = async (selectedItem, input) => {
    this.setState({ ajaxRequest: true, disabled: true });
    const data = { ...this.state.data };
    const result = await Transaction.getRateDetails({
      customer: data.customer, supplier: selectedItem.value,
      product_type: this.state.constants.PRODUCTS.TRADEFINANCE
    });
    if (result.data.rate_guid)
      this.setState({ readOnly: false });
    else {
      toast.error("Please assign a Rate to this Combination..!!");
      this.setState({ ajaxRequest: false, disabled: false });
      return false;
    }
    data['currencyStatus'] = result.data.is_currency_on;

    data['other_programs'] = result.data.other_programs;

    data[input] = selectedItem.value;
    data[input + 'Option'] = selectedItem;
    this.setState({ data, ajaxRequest: false, disabled: false });
  }
  handleDueDateChange = (date) => {
    const data = { ...this.state.data };
    data["inv_due_date"] = date;
    data["inv_revised_due_date"] = null;
    this.setState({ data });
    this.setState({ data }, () => { this.displayFee(); this.revisedDateLimit(); });
  };
  handlerevisedDateChange = (date) => {
    const data = { ...this.state.data };
    data["inv_revised_due_date"] = date;
    this.setState({ data }, () => { this.displayFee(); this.revisedDateLimit(); });
  };
  handlePaySupplierDate = (date) => {
    const data = { ...this.state.data };
    data["pay_supplier_date"] = date;
    this.setState({ data }, () => { this.displayFee(); this.revisedDateLimit(); });
  }
  revisedDateLimit = async () => {
    const { data } = this.state;
    const { ParseDate } = Utility(); // Instance for utility library.
    if (
      (data.customer != null || data.supplier != null) &&
      data.trans_date != null &&
      data.inv_due_date != null
    ) {
      data["trans_date"] = ParseDate(data.trans_date);

      if (data.inv_due_date != null && data.trans_date != null) {
        data["inv_due_date"] = ParseDate(data.inv_due_date);
      }
      data["product_type"] = this.state.constants.PRODUCTS.TRADEFINANCE;
      try {
        const { success, message, maxRevisedDate, extension_field } = await Transaction.maxRevisedDate(data);
        if (success === false) toast.error(message);
        else {
          data["maxRevisedDate"] = maxRevisedDate;
          data['extension_field'] = (extension_field == 'invoice_date') ? data['trans_date'] : data['inv_due_date'];
          data['revisedLoading'] = false;
          this.setState({ data });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  displayFee = async () => {
    this.setState({ loading: true });
    const { data } = this.state;
    const { ParseDate } = Utility(); // Instance for utility library.

    data["trans_date"] = (data.trans_date != null) ? ParseDate(data.trans_date) : null;

    if (data.inv_due_date != null)
      data["inv_due_date"] = ParseDate(data.inv_due_date);

    if (data.inv_revised_due_date != null)
      data["inv_revised_due_date"] = ParseDate(data.inv_revised_due_date);

    if (data.pay_supplier_date != null)
      data["pay_supplier_date"] = ParseDate(data.pay_supplier_date);

    data["product_type"] = this.state.constants.PRODUCTS.TRADEFINANCE;

    try {
      const { success, message, rate } = await Transaction.displayFee(data, "customer");
      if (success === false) toast.error(message);
      else {
        data["fee"] = message;
        data['rate_guid'] = rate
        this.setState({ data });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
  };

  //*** Updating state of data when input changed ***/
  handleInvDateChange = (date) => {
    if (date) {
      const data = { ...this.state.data };
      data["trans_date"] = date;
      data["inv_revised_due_date"] = null;
      data["inv_due_date"] = null;
      this.setState({ data }, () => {
        this.displayFee(); this.dueDateLimit();
        this.revisedDateLimit();
      });
    }

  };

  handleAmount = () => {
    const data = { ...this.state.data };
    this.setState({ data }, () => { this.displayFee() });
  };

  setAmount = (event, maskedvalue, floatvalue) => {
    const data = { ...this.state.data };
    data["amount"] = floatvalue;
    if (this.state.data['amount'] != floatvalue)
      this.setState({ data });
  };

  setFcy = (event, maskedvalue, floatvalue) => {
    const data = { ...this.state.data };
    data["fcyAmount"] = floatvalue;
    if (this.state.data['fcyAmount'] != floatvalue)
      this.setState({ data });
  };

  focusInput = (inputName) => { };

  handleFileReader = async () => {
    let files = this.state.files;
    let fileslist = [];
    const filePromises = files.map((fileItem) => {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(fileItem);
        reader.onload = (e) => {
          fileslist.push({
            name: fileItem.name,
            file: e.target.result,
            type: fileItem.type,
            size: fileItem.size,
          });
          resolve();
        };
      });
    });
    await Promise.all(filePromises); // Wait for all files to be read
    return fileslist;
  };  

  async componentDidMount() {

    const { constants } = auth.getCurrentUser();
    this.handleSyncContext(constants);

    this.fetchDetails();

  }
  fetchDetails = () => {
    const data = { ...this.state.data };
    if (this.props.files) {
      this.setState({ files: [this.props.files] });
    }
    if (this.props.data) {
      const propData = this.props.data;
      if (this.props.data.supplier) {
        this.setState({ readOnly: false });
      }
      this.setState({ data: propData }, () => { this.dueDateLimit(); this.displayFee(); this.revisedDateLimit(); });
    }
  }

  handleSyncContext = async (constants) => {
    if ((this.context) && (this.context.entityDetails !== null)) {
      const products = this.context.entityDetails.products;
      this.setState({ products });
      this.setState({ constants });
      if (RoleChecker.check(products, constants.ROLES.TFSUPPLIER)) {
        const data = { ...this.state.data };
        data["supplier"] = this.props.a_guid;
        this.setState({ data });
      }
      else if (RoleChecker.check(products, constants.ROLES.TFCUSTOMER)) {
        const data = { ...this.state.data };
        data["customer"] = this.props.a_guid;
        this.setState({ data });
      }

    } else {
      const { products } = auth.getCurrentUser();
      this.setState({ products });
      this.setState({ constants });
    }
  }
  dueDateLimit = async () => {
    const data = { ...this.state.data };
    const { ParseDate } = Utility(); // Instance for utility library.
    if (
      (data.customer != null || data.supplier != null) &&
      data.trans_date != null
    ) {
      data["trans_date"] = ParseDate(data.trans_date);
      data["product_type"] = this.state.constants.PRODUCTS.TRADEFINANCE;
      try {
        const { success, message, maxInvoiceDueDate, extension_field } = await Transaction.maxInvoiceDuedDate(data);
        if (success === false) toast.error(message);
        else {
          data['maxInvoiceDueDate'] = maxInvoiceDueDate;
          data['extension_field'] = (extension_field == 'invoice_date') ? data['trans_date'] : data['inv_due_date'];
          this.setState({ data });
        }
      } catch (error) {
      }
    }
  };

  handleCurrencyChange = (currency) => {
    if (currency) {
      const data = { ...this.state.data };
      data["currency"] = currency;
      this.setState({ data });
    }
  };

  render() {
    const { data, ajaxRequest, constants, products } = this.state;
    const { category } = auth.getCurrentUser();
    const { ParseDate } = Utility(); // Instance for utility library.

    return (
      <React.Fragment>
        <div style={{ position: "relative", top: "50%", left: "50%", zIndex: "100" }} >{ajaxRequest && <LoadingSpinner />}</div>


        <Modal.Header closeButton className="justify-content-center">
          <div className="d-block pt-3 text-center w-100 ml-4">
            <img width={"50px"} src={InvIcon} className="mb-2" />
            <h4 className="modal-title">{this.state.data.trans_guid ? "Update Invoice" : "New Invoice"}</h4>
            <small className="">Please enter the invoice details below.</small>
          </div>

        </Modal.Header>


        <form /*onSubmit={this.handleSubmit}*/>
          <Modal.Body>
            <FormRow >
              <div className="modal-body">

                {ajaxRequest && <LoadingSpinner />}
                {data.confirmation === false && (
                  <>
                    {((this.context && constants.ROLES && (RoleChecker.check(products, constants.ROLES.CUSTOMER) || (RoleChecker.check(products, constants.ROLES.TFCUSTOMER))))) && (
                      <>
                        <div className="form-group required">

                          <UserType
                            onSelectItem={(selectedItem) => { this.setValues(selectedItem, "supplier"); this.revisedDateLimit(); }}
                            type="mappedsupplier"
                            userId={this.props.a_guid ? this.props.a_guid : ""}
                            page={this.props.page ? this.props.page : ""}
                            products={this.state.products}
                            heading="Supplier"
                            InputOption={{ value: this.state.data.supplier }}
                            isDisabled={data.trans_guid ? true : false}
                            productType={constants && constants.PRODUCTS && constants.PRODUCTS.TRADEFINANCE}
                            addNew={data.trans_guid ? false : true}
                          />
                        </div>
                      </>
                    )}
                    {((!this.context && (category == "Business Partner" || category == "International Office" || category == "Country Office"))) && (
                      <>
                        <div className="form-group">
                          <UserType
                            onSelectItem={(selectedItem) => {
                              this.setValues(selectedItem, "supplier");
                              this.revisedDateLimit();
                            }}
                            type="mappedsupplier"
                            userId={this.props.a_guid ? this.props.a_guid : ""}
                            page={this.props.page ? this.props.page : ""}
                            products={this.state.products}
                            heading="Supplier"
                            InputOption={{ value: this.state.data.supplier }}
                            productType={constants && constants.PRODUCTS && constants.PRODUCTS.TRADEFINANCE}
                            addNew={data.trans_guid ? false : true}
                          />
                        </div>
                      </>
                    )}
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Purchase Order</label>
                          <div><TextInput name="purchase_order_no" value={data.purchase_order_no} label="Purchase Order Number" reference={this.purchase_order_no} onChange={this.handleChange} type="" readonly={this.state.readOnly} title={this.state.readOnly ? "Please select a supplier " : ""} disabled></TextInput></div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group required">
                          <label className="control-label">Invoice Number</label>
                          <div><TextInput name="invoice_number" value={data.invoice_number} label="Invoice Number" reference={this.invRef} onChange={this.handleChange} type="" readonly={this.state.readOnly} title={this.state.readOnly ? "Please select a supplier " : ""}  ></TextInput></div>
                        </div>
                      </div>
                    </div>
                    {/*------------------------------------------------------------------*/}
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group required">
                          <label className="control-label">Amount</label>
                          <div >  <CurrencyText placeholder="Amount + Tax" name="amount" onBlur={this.handleAmount} readonly={this.state.readOnly} onChangeEvent={this.setAmount} value={data.amount > 0 ? data.amount : null} title={this.state.readOnly ? "Please select a supplier" : ""} /></div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group required">
                          <label className="control-label">Invoice Date</label>
                          <div > <DateTimePicker enableWeekDays={true} name="trans_date" type="normal" readOnly={this.state.readOnly} onChange={this.handleInvDateChange} selected={typeof data.trans_date !== "object" ? new Date(Date.parse(data.trans_date)) : data.trans_date} format="dd/MM/y" ></DateTimePicker></div>
                        </div>
                      </div>
                    </div>
                    {/*------------------------------------------------------------------*/}
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group required">
                          <label className="control-label">Original Due Date</label>
                          <div >
                            <DateTimePicker utcOffset={0} name="inv_due_date" type="normal"
                              readOnly={this.state.readOnly || data.trans_date === null} onChange={this.handleDueDateChange}
                              minDate={new Date(Date.parse(data.trans_date))} selected={typeof data.inv_due_date !== "object" ? new Date(Date.parse(data.inv_due_date)) : data.inv_due_date} format="dd/MM/y"
                              maxDate={typeof data.maxInvoiceDueDate !== "object" ? new Date(Date.parse(data.maxInvoiceDueDate)) : data.maxInvoiceDueDate} enableWeekDays={true} />
                          </div>
                        </div>
                      </div>


                      <div className="col-lg-6">
                        <div className="form-group ">
                          <label className="">Revised Due Date</label><div>{data.revisedLoading && (<div className="col-lg-2"><LoadingSpinner height={25} width={20} normal={true} type="ThreeDots" /></div>)}
                            {!data.revisedLoading && (
                              <DateTimePicker
                                utcOffset={0} name="inv_revised_due_date" readOnly={this.state.readOnly || data.inv_due_date === null}
                                type="normal" onChange={this.handlerevisedDateChange}
                                selected={typeof data.inv_revised_due_date !== "object" ? new Date(Date.parse(data.inv_revised_due_date)) : data.inv_revised_due_date}
                                minDate={new Date(Date.parse(data.extension_field))}
                                maxDate={typeof data.maxRevisedDate !== "object" ? new Date(Date.parse(data.maxRevisedDate)) : data.maxRevisedDate}
                                format="dd/MM/y"
                              />)}</div>
                        </div>
                      </div>

                    </div>

                    {/*------------------------------------------------------------------------------*/}
                    <div className="row">
                      <div className="col-lg-6 align-self-center">
                        <div className="form-group ">



                          <div className="custom-control d-flex custom-checkbox small">
                            <input
                              id={"pay_supplier_check"}
                              className="custom-control-input"
                              type="checkbox"
                              checked={data.pay_supplier}
                              disabled={data.inv_due_date === null}
                              onClick={() => {
                                const data = { ...this.state.data }
                                data['pay_supplier'] = !data['pay_supplier'];
                                data['pay_supplier_date'] = data['pay_supplier'] === true ? data.inv_due_date : null;
                                this.setState({ data }, () => { this.displayFee(); this.revisedDateLimit(); });
                              }}

                            ></input>
                            <label
                              className="custom-control-label pt-1 text-secondary"
                              htmlFor={"pay_supplier_check"}
                            >
                              Pay your supplier early?  <i data-multiline="true" data-tip="Clicking this box and entering date will mean the customer will pay the early payment cost.<br/> To add early payment at the supplier expense do so directly on the invoice." class="fa fa-info-circle"></i>
                            </label>
                            <ReactTooltip backgroundColor="#11698e" />
                          </div>

                          <div>
                          </div>
                        </div>

                      </div>

                      {data.pay_supplier === true && (
                        <div className="col-lg-6">
                          <div className="form-group ">

                            <div >
                              <DateTimePicker
                                utcOffset={0}
                                name="pay_supplier_date"
                                type="normal"
                                readOnly={this.state.readOnly}
                                onChange={this.handlePaySupplierDate}
                                minDate={new Date(Date.parse(data.trans_date))}
                                selected={typeof data.pay_supplier_date !== "object" ? new Date(Date.parse(data.pay_supplier_date)) : data.pay_supplier_date} format="dd/MM/y"
                                maxDate={typeof data.inv_due_date !== "object" ? new Date(Date.parse(data.inv_due_date)) : data.inv_due_date}
                                enableWeekDays={true}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                    </div>
                    {/*------------------------------------------------------------------------*/}

                    {data.currencyStatus == "active" && (
                      <CurrencyDetails handleChange={this.handleChange} handleCurrencyChange={this.handleCurrencyChange}
                        data={{ currency: data.currency, exchangeRate: data.exchangeRate, fcyAmount: data.fcyAmount, amount: data.amount }}
                        setFcy={this.setFcy}
                      />
                    )}

                    {!this.state.data.trans_guid && (
                      <>
                        <FormRow className="row m-t-md"> <FormColumnLg col="4"> <label>Invoice Files</label></FormColumnLg></FormRow>

                        <FormRow className="row">
                          <FormColumnLg col="12">
                            <MultipleFileInput files={this.state.files} allowMultiple={true}
                              onupdatefiles={(fileItems) => {
                                const data = { ...this.state.data };
                                if (fileItems[0])
                                  data["document_name"] = fileItems[0].file.name;
                                this.setState({
                                  files: fileItems.map((fileItem) => fileItem.file),
                                  data,
                                });
                              }}
                            ></MultipleFileInput>
                          </FormColumnLg>
                        </FormRow>
                      </>
                    )}


                    {this.props.data && this.props.data.attachments !== undefined && this.props.data.attachments.map((attachment, index) => (
                        <FormColumnLg key={index} col="12">
                          <div className=" alert alert-info">
                              <div className="row">
                                  <div className="col-sm-12">{attachment.inv_file_name}</div>
                                  {/* <div className="col-sm-4">
                                      <Link
                                          title="View attachment"
                                          className="pull-right cursor-link"
                                          onClick={() => { fetchAttachment(attachment.attachment_guid, "view") }}
                                        ><i className="fa fa-file-o"></i>
                                      </Link>
                                      <Link
                                          title="Download attachment"
                                          className="pull-right cursor-link mr-2"
                                          onClick={() => { fetchAttachment(attachment.attachment_guid, "download", attachment.inv_file_name) }}
                                        ><i className="fa fa-download"></i>
                                      </Link>
                                  </div> */}
                              </div>
                          </div>
                        </FormColumnLg>
                      ))}


                    <TermsConditions />
                  </>
                )}
                {data.confirmation == true && (<Confirmation ref={this.confirmationRef} constants={constants} products={products} category={category}></Confirmation>)}
              </div>
            </FormRow>
            <AmountInfo loading={this.state.loading} fee={data.fee} dueDate={data.inv_due_date} revDueDate={data.inv_revised_due_date} dateField={(data.inv_revised_due_date != null ? ParseDate(data.inv_revised_due_date, 'DD/MM/YY') : (data.inv_due_date != null ? ParseDate(data.inv_due_date, 'DD/MM/YY') : ""))} ></AmountInfo>
          </Modal.Body>
          <Modal.Footer>
            {this.props.backToList && data.confirmation == false && (
              <ModalCloseButton onClick={(e) => { this.props.backToList() }} label="Back" className="btn btn-light " type="button"></ModalCloseButton>
            )}
            {data.confirmation == true && (<ModalCloseButton onClick={this.chnageConfirmation} label="Back" className="btn btn-light"></ModalCloseButton>)}
            {data.confirmation == false && !this.state.readOnly && (<AddButton disabled={this.state.disabled} onClick={this.confirmation} label="Next" style="btn btn-primary  "></AddButton>)}
            {(this.state.data.other_programs === 'yes' && category === constants.CATEGORY.TRADERS) && 
              <div className="alert alert-danger">
                <i className="fa fa-info-circle mr-2"></i>
                Unfortunately, you currently do not have the authorization to create invoices with this supplier.
              </div>
            }
            {data.confirmation == true && (<GeneralButton onClick={this.handleSubmit} disabled={this.state.disabled} className="btn btn-primary" name="Submit" ></GeneralButton>)}
          </Modal.Footer>
        </form >
      </React.Fragment >
    );
  }
}
TfCreateInvoiceCustomer.contextType = EntityContext;
export default TfCreateInvoiceCustomer;