import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  FormRow,
  FormColumnMd,
} from "../../../../common/containers/formContainer";
import Trolley from "../../../../../img/icons/trolley.svg";
import { TextInput } from "../../../../common/inputs";
import { GeneralButton } from "../../../../common/containers/button";
import useApi from "../../../../../hooks/useApi";
import MapEntity from "./mapEntity";
import CreateInstantEntity from "./CreateInstantEntity";
import LoadingSpinner from "../../../../common/loaders/loadingSpinner";
import { toast } from "react-toastify";
import entityService from "../../../../../services/entityService";
import AbnSearch from "../../../../entities/network/abnNameSearch";

function NewTrader({ entity_id, role, title, source, onCloseModal, product }) {
  //------------------Custom hook to handle form data-----------------------------
  const [searchBy, setSearchBy] = useState(0);
  const [entitySearchKey, setEntitySearchKey] = useState("");
  const [abnSearchModal, setAbnSearchModal] = useState(false);
  const [entityData, setEntityData] = useState(null);
  const [asicResponse, setAsicResponse] = useState(null);
  const [request, setRequest] = useState(false);
  //------------Api Services--------------------------------------------
  const entitySearchApi = useApi(entityService.searchByAbn);

  const searchEntity = async () => {
    if (entitySearchKey === "") {
      toast.error("Please enter ABN on the field.");
      return;
    }
    setAbnSearchModal(true);
  };
  const handleAbnSearch = async (result, type) => {
    setRequest(true);
    if (type === "asic") {
      setAsicResponse({
        entityFound: true,
        organisation: result.organisation,
        abn: result.abn,
      });
      setEntityData({ entityFound: true, type: "asic" });
    } else {
      const { data } = await entitySearchApi.request({
        abn_number: result.a_guid,
      });
      let result1 = data.result;
      result1.entityContacts
        .filter((item) => item.is_primary == "yes")
        .map((val) => {
          result1["contact_id"] = val.contact_id;
          val.isChecked = true;
          return val;
        });
      result1["type"] = "api";
      setEntityData(result1);
    }
  };
  return (
    <React.Fragment>
      {/*------------------ABN/Name search-------------------*/}
      <Modal
        show={abnSearchModal}
        onHide={() => setAbnSearchModal(false)}
        size="lg"
        centered
      >
        <AbnSearch
          searchBy={searchBy}
          entitySearchKey={entitySearchKey.trim()}
          handleAbnSearch={handleAbnSearch}
          onClose={() => setAbnSearchModal(false)}
        />
      </Modal>
      {/*---------------------------------------------------------*/}

      {entityData == null && (
        <div className="row">
          <div className="col-lg-12  p-4">
            {entitySearchApi.loading === true && <LoadingSpinner />}
            {!request && (
              <>
                <FormRow className="">
                  <div className="m-md">
                    Please search using &nbsp;
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        id="orgname"
                        type="radio"
                        value="orgname"
                        className="custom-control-input"
                        onClick={() => setSearchBy(0)}
                        checked={searchBy === 0 ? true : false}
                      ></input>
                      <label className="custom-control-label" htmlFor="orgname">
                        Name
                      </label>
                    </div>
                    &nbsp;
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        id="orgnum"
                        type="radio"
                        value="orgnum"
                        className="custom-control-input"
                        onClick={() => setSearchBy(1)}
                        checked={searchBy === 1 ? true : false}
                      ></input>
                      <label className="custom-control-label" htmlFor="orgnum">
                        ABN
                      </label>
                    </div>
                  </div>
                </FormRow>

                <FormRow>
                  <FormColumnMd col={8}>
                    <TextInput
                      name="abn_number"
                      placeholder={`Search by ${
                        searchBy === 0 ? "Organisation name" : "ABN"
                      }`}
                      class="form-control  "
                      value={entitySearchKey}
                      onChange={({ target }) =>
                        setEntitySearchKey(target.value)
                      }
                      type="normal"
                    ></TextInput>
                  </FormColumnMd>

                  <FormColumnMd col={4}>
                    <GeneralButton
                      onClick={() => searchEntity()}
                      className="btn btn-primary pull-right"
                      faIcon="fa fa-search"
                      name="Search"
                    ></GeneralButton>
                  </FormColumnMd>
                </FormRow>
              </>
            )}
          </div>
        </div>
      )}

      {entityData !== null && entityData.entityFound && (
        <>
          {entityData.type == "api" ? (
            <MapEntity
              contacts={entityData.entityContacts}
              selected_contact={entityData.contact_id}
              role={role}
              entityDetails={entityData.entityDetails}
              entity_id={entity_id}
              onCloseModal={() => {
                onCloseModal();
              }}
              source={source}
              product={product}
            />
          ) : (
            <CreateInstantEntity
              role={role}
              onCloseModal={() => onCloseModal()}
              searchData={{
                abn: asicResponse.abn,
                organisation: asicResponse.organisation,
                searchBy: searchBy,
              }}
              entity_id={entity_id}
              source={source}
              product={product}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default NewTrader;
