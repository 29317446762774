import React, { useEffect, useState } from "react";
import useForm from "../../../hooks/useForm";
import { TextInput } from "../../common/inputs";
import { SaveButton } from "../../common/containers/button";
import { FormRow } from "../../common/containers/formContainer";
import CommonService from "../../supply-chain/services/commonService";
import useApi from "../../../hooks/useApi";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import { BPList, CountriesList, ProductsList } from "../../slplusTools";
import auth from "../../../services/authService";

function CreateInstantEntity({
  role,
  onCloseModal,
  searchData,
  entity_id,
  source,
  title,
  accountType,
  handleSelectedItem = null,
  ...restProps
}) {
  //------------------Validation Schema-----------------------------
  const schema = Joi.object() // Form validation schema object
    .keys({
      accType: Joi.string().required().valid("intl", "local"),
      organization: Joi.string().required().label("Organisation"),
      first_name: Joi.string()
        .regex(/^(?!^\d+$)^.+$/)
        .required()
        .error(() => {
          return { message: "Please enter a valid First Name" };
        }),
      last_name: Joi.string()
        .regex(/^(?!^\d+$)^.+$/)
        .required()
        .error(() => {
          return { message: "Please enter a valid Last Name" };
        }),
      //ab_number: Joi.string().required().label("ABN"),
      email: Joi.string().email().required().label("Email"),
      phone_no: Joi.string().required().label("Phone Number"),
    })
    .unknown(true)
    .when(Joi.object({ accType: Joi.string().valid("local") }).unknown(true), {
      then: Joi.object({
        ab_number: Joi.string().required().label("ABN"),
      }),
    });

  const { constants } = auth.getCurrentUser();
  const createMappingApi = useApi(CommonService.createUserWithMapping);
  const generateUndisclosedDetailsApi = useApi(
    CommonService.generateUndisclosedDetails
  );

  const [countryId, setCountryId] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  //------------------Save Entity Details-----------------------------
  const saveMapping = async () => {
    let reqValues = { ...values };

    if (!reqValues.owner && source == "accounts") {
      toast.error("Choose Partner.");
      return;
    }

    if (reqValues.role_type.length < 1 && source == "accounts") {
      toast.error("Choose Facilities.");
      return;
    }
    reqValues["entity"] = entity_id;
    reqValues["role"] = role;
    let reqArray = [];
    if (source == "accounts") {
      let activeProducts = reqValues.role_type.filter(
        (item) => item.isChecked == true
      );
      activeProducts.map((activeProduct) => {
        activeProduct["roles"]
          .filter((roleFilter) => roleFilter.isChecked === true)
          .map((activeRole) => {
            reqArray.push({
              product: activeProduct.id,
              role: activeRole.id,
              role_name: activeRole.role_name,
              isChecked: activeRole.isChecked,
            });
          });
      });
    } else if (source == "network") {
      let productID =
        title == constants.ROLES.DFDEBTOR
          ? constants.PRODUCTS.DEBTORFINANACE
          : title == constants.ROLES.DEBTOR
          ? constants.PRODUCTS.INVOICE
          : title == constants.ROLES.TFSUPPLIER
          ? constants.PRODUCTS.TRADEFINANCE
          : constants.PRODUCTS.SUPPLYCHAIN;

      let roleID =
        title == constants.ROLES.DFDEBTOR
          ? constants.ROLES_IDS.DFDEBTOR
          : title == constants.ROLES.DEBTOR
          ? constants.ROLES_IDS.DEBTOR
          : title == constants.ROLES.TFSUPPLIER
          ? constants.ROLES_IDS.TFSUPPLIER
          : constants.ROLES_IDS.SUPPLIER;

      let roleName =
        title == constants.ROLES.DFDEBTOR
          ? constants.ROLES.DFDEBTOR
          : title == constants.ROLES.DEBTOR
          ? constants.ROLES.DEBTOR
          : title == constants.ROLES.TFSUPPLIER
          ? constants.ROLES.TFSUPPLIER
          : constants.ROLES.SUPPLIER;

      reqArray.push({
        product: productID,
        role: roleID,
        role_name: roleName,
        isChecked: true,
      });
    }
    reqValues.products = reqArray;
    const { data: res } = await createMappingApi.request(reqValues);

    if (res.success === true) {
      toast.success(res.message);
      setTimeout(() => {
        onCloseModal();
        handleSelectedItem && handleSelectedItem(res.rows);
      }, 1500);
    } else toast.error(res.message);
  };

  const handleSelect = (selectedValue, value) => {
    setValues({
      ...values,
      [selectedValue]: value,
    });
  };

  const handleRoleChange = (items) => {
    setValues({ ...values, role_type: items });
  };
  const { values, setValues, handleChange, handleSubmit } = useForm(
    saveMapping,
    schema
  );
  const handleCheckboxChange = async (e) => {
    if (e.target.value == 0) {
      const { data } = await generateUndisclosedDetailsApi.request();
      setValues({
        ...values,
        // first_name: data.firstname,
        // last_name: data.lastname,
        // email: data.email,
        disclosure: 0,
      });
    } else {
      setValues({
        ...values,
        first_name: "",
        last_name: "",
        email: "",
        disclosure: 1,
      });
    }
  };

  useEffect(() => {
    if (accountType == "intl") {
      setValues({
        ...values,
        readonly: false,
        source: source,
        role_type: [],
        accType: "intl",
        disclosure: 1,

        organization: restProps.data
          ? restProps.data.organisation
          : values.organization,
        email: restProps.data ? restProps.data.email : values.email,
        phone_no: restProps.data ? restProps.data.phone : values.phone_no,
      });
    } else {
      setValues({
        ...values,
        readonly: true,
        ab_number: searchData.abn,
        source: source,
        role_type: [],
        accType: "local",
        disclosure: 1,

        organization: restProps.data
          ? restProps.data.organisation
          : searchData.organisation,
        email: restProps.data ? restProps.data.email : values.email,
        phone_no: restProps.data ? restProps.data.phone : values.phone_no,
      });
    }
  }, []);

  useEffect(() => {
    if (countryId !== null || ownerId !== null) {
      let updateValues = { ...values };
      updateValues["country"] = countryId;
      updateValues["owner"] = ownerId;
      setValues(updateValues);
    }
  }, [countryId, ownerId]);

  return (
    <>
      {(createMappingApi.loading === true ||
        generateUndisclosedDetailsApi.loading) && <LoadingSpinner />}
      <form className="form-horizontal p-4" onSubmit={handleSubmit}>
        {source == "accounts" && (
          <>
            <CountriesList
              label="Country"
              type="horizontal"
              selectedCountry={values.country}
              onSelectItem={({ id }) => {
                setCountryId(id);
              }}
            />
            <BPList
              selectedBp={values.owner}
              label="Owner"
              type="horizontal"
              country={values.country}
              onSelectItem={({ value }) => {
                setOwnerId(value);
              }}
            />
          </>
        )}
        {/* { accountType == "intl" && ( */}
        <FormRow className="row  m-b-md ">
          <label className="col-lg-4">Disclosure</label>
          <div className="col-lg-8">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                onClick={handleCheckboxChange}
                checked={values.disclosure == "1" ? true : false}
                type="radio"
                id={`customCheckDisclosed`}
                name="Disclosed"
                value="1"
                class="custom-control-input"
              />
              <label class="custom-control-label" for={`customCheckDisclosed`}>
                Disclosed
              </label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input
                onClick={handleCheckboxChange}
                checked={values.disclosure == "0" ? true : false}
                type="radio"
                id={`customCheckUnDisclosed`}
                name="Un Disclosed"
                value="0"
                class="custom-control-input"
              />
              <label
                class="custom-control-label"
                for={`customCheckUnDisclosed`}
              >
                Undisclosed
              </label>
            </div>
          </div>
        </FormRow>
        <FormRow className="row  m-b-md ">
          {values.disclosure != "0" ? (
                ""
          ) : (
            <div
              class="alert alert-info text-center align-center fade show "
              role="alert"
            >
              Selecting undisclosed will ensure we maintain confidentiality
              with your supplier that you are using finance.
            </div>
          )}
        </FormRow>
        {/* )} */}
        {source == "accounts" && (
          <>
            <FormRow className="row  m-b-md ">
              <div className="col-lg-12 bg-light b-dashed-2 p-2">
                <ProductsList
                  col={6}
                  onSelectItem={(items) => handleRoleChange(items)}
                  type="secondary"
                />
              </div>
            </FormRow>
          </>
        )}

        <TextInput
          value={values.organization}
          /*readonly={values.readonly}*/
          onChange={handleChange}
          name="organization"
          placeholder="Organisation"
          type="vertical"
          label="Organisation"
          mandatory
        />
        <>
          <TextInput
            name="first_name"
            // readonly={values.disclosure == "0" ? true : false}
            value={values.first_name}
            onChange={handleChange}
            placeholder="First Name"
            type="vertical"
            label="First Name"
            mandatory
          />

          <TextInput
            name="last_name"
            // readonly={values.disclosure == "0" ? true : false}
            value={values.last_name}
            onChange={handleChange}
            placeholder="Last Name"
            type="vertical"
            label="Last Name"
            mandatory
          />
        </>
        {accountType !== "intl" && (
          <TextInput
            value={values.ab_number}
            onChange={handleChange}
            name="ab_number"
            placeholder="ABN"
            readonly={values.readonly}
            type="vertical"
            label="ABN"
            mandatory
          />
        )}
        <>
          <TextInput
            value={values.email}
            // readonly={values.disclosure == "0" ? true : false}
            onChange={handleChange}
            name="email"
            mandatory
            type="vertical"
            label="Email"
          />

          <TextInput
            value={values.phone_no}
            onChange={handleChange}
            name="phone_no"
            placeholder="Phone"
            type="vertical"
            label="Phone"
            mandatory
          />
        </>

        <div className="text-right">
          <SaveButton disabled={createMappingApi.loading} label="Submit" />
        </div>
      </form>
    </>
  );
}

export default CreateInstantEntity;
