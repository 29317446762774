import React, { Component } from "react";
// import Joyride from 'react-joyride';
import queryString from 'query-string';
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import coreService from "../../services/coreService";
import LoadingSpinner from '../common/loaders/loadingSpinner';
import tourList from "../../config/tour.js";
// import TourVdoList from "../../tourVideo.js";
import RequestTourVdo from "../../img/Tour/Request_a_tour/Request_a_tour.mp4";

class StartTour extends Component {

    constructor(props) {
        super(props);
        this.state = {
            run: false,
            steps: [],
            tourRequestModal: false,
            loading: false,
            videoModal: false,
            vdo_desc: "",
            vdo_link: "",
            requestTourVdo: RequestTourVdo,
            hasvideo: false,
            hasTour: false,
        };
        this.innerRef = React.createRef();

    }

    componentDidMount = () => {
        this.fetchLocationAndLoadTour();
    };

    // componentWillReceiveProps(nextProps) {
    //     this.fetchLocationAndLoadTour();
    //     console.log("componentWillReceiveProps");
    // }
    componentDidUpdate = (prevProps) => {
        let paramList = queryString.parse(this.props.location.search);
        let new_pathname = this.props.location.pathname.trim('/');
        let new_path_array = new_pathname.split('/');
        new_path_array = new_path_array.filter(function (e) { return e });
        let prev_pathname = prevProps.location.pathname.trim('/');
        let prev_path_array = prev_pathname.split('/');
        prev_path_array = prev_path_array.filter(function (e) { return e });
        let newParam = "";
        let prevParam = "";
        let flag = false;
        if (paramList.sessionState != undefined) {
            newParam = paramList.sessionState;
        }
        if (prevProps.location.query.sessionState != undefined) {
            prevParam = prevProps.location.query.sessionState;
        }

        if (newParam != prevParam || new_path_array[new_path_array.length - 1] != prev_path_array[prev_path_array.length - 1]) {
            this.fetchLocationAndLoadTour();
        }


    }

    fetchLocationAndLoadTour = () => {
        this.setState({ run: false });
        let setStep = false;
        let pathname = this.props.location.pathname.trim('/');
        let paramList = queryString.parse(this.props.location.search);
        let path_array = pathname.split('/');
        let success = true;
        path_array = path_array.filter(function (e) { return e });

        let stateParam = "";

        if (paramList.sessionState == undefined) {
            if (path_array[path_array.length - 1] == "debtorfinances"
                || path_array[path_array.length - 1] == "ifinvoices"
                || path_array[path_array.length - 1] == "loan"
                || path_array[path_array.length - 1] == "sctransactions"
                || path_array[path_array.length - 1] == "tftransactions") {
                stateParam = "invoice";
            }
        } else {
            if (paramList.sessionState == 'wobinvoice'
                || paramList.sessionState == 'ifinvoice'
                || paramList.sessionState == 'scfinvoice'
                || paramList.sessionState == 'tfinvoice'
                || paramList.sessionState == 'loaninvoice') {
                stateParam = "invoice";
            } else {
                stateParam = paramList.sessionState;
            }
        }

        let step = [];
        if (path_array.length == 1) {
            switch (path_array[0]) {
                case 'entities':
                    step = tourList['entities']['list'];
                    setStep = true;
                    break;
                case 'prospects':
                    step = tourList['prospects']['list'];
                    setStep = true;
                    break;
                case 'introducer':
                    step = tourList['introducer']['list'];
                    setStep = true;
                    break;
            }
        }
        if (setStep == false) {
            for (let index = 0; index < path_array.length; index++) {
                if (index == 0) {
                    if (tourList.hasOwnProperty(path_array[index])) {
                        step = tourList[path_array[index]];
                    } else {
                        success = false;
                        break;
                    }
                } else {
                    if (step.hasOwnProperty(path_array[index])) {
                        step = step[path_array[index]];
                    } else {
                        success = false;
                        break;
                    }
                }

            }

        }

        if (success === true) {
            if (stateParam !== "") {
                if (step.hasOwnProperty(stateParam)) {
                    step = step[stateParam];
                    this.setState({ steps: step });
                } else {
                    this.setState({ steps: [] });
                }

            } else {
                this.setState({ steps: step });
            }
        } else {
            this.setState({ steps: [] });
        }
    }

    handleClick = e => {
        e.preventDefault();
        if (Object.keys(this.state.steps).length == 0) {
            this.setState({ tourRequestModal: true });
        } else {
            let hasVdo = false;
            let hastour = false;
            let vdo = [];
            if (this.state.steps.video.length > 0) {
                hasVdo = true;
                vdo = this.state.steps.video[Math.floor(Math.random() * this.state.steps.video.length)];
            }
            if (this.state.steps.tour.length > 0) {
                hastour = true;
            }

            this.setState({
                vdo_link: hasVdo === true ? require('../../img/Tour/' + vdo.link) : '',
                //vdo_link: hasVdo === true ? vdo.link : '',
                vdo_desc: hasVdo === true ? vdo.description : '',
                hasvideo: hasVdo,
                hasTour: hastour,
            }, () => { this.setState({ videoModal: true }) });
        }
    };

    handleStartTour = e => {

        this.setState({ videoModal: false });
        if (Object.keys(this.state.steps).length == 0) {
            this.setState({ tourRequestModal: true });
        } else {
            this.setState({ run: true });
        }
    };
    handleJoyrideCallback = (e) => {
        if (e.action == 'reset' || e.action == 'stop' || e.action == 'close')
            this.setState({ run: false });
    };

    handleRequestTour = async () => {
        let pathname = window.location.pathname.trim('/');
        let notificationResponse = "";
        this.setState({ loading: true });
        notificationResponse = await coreService.SendNotification({
            'title': 'tour-adding-request',
            'additionalParams': { 'page': pathname },
            'notify_to': 'intl_office'
        });
        notificationResponse = await coreService.SendNotification({
            'title': 'tour-adding-request',
            'additionalParams': { 'page': pathname },
            'notify_to': 'country_office'
        });
        this.setState({ loading: false });
        if (notificationResponse.data.success === true) {
            toast.success('Your request has been submitted');
        } else {
            toast.error(notificationResponse.data.message);
        }
    }

    handleVdoCloseModal = () => {
        this.setState({ videoModal: false });
    }


    render() {

        return (
            <>
                {this.state.loading === true && <LoadingSpinner />}
                <Modal
                    show={this.state.tourRequestModal}
                    size="lg"
                    onHide={(e) => this.setState({ tourRequestModal: false })}
                >
                    <Modal.Header closeButton>
                    </Modal.Header>

                    <Modal.Body>
                        <center>
                            <video width="750" height="500" controls autoPlay>
                                <source src={this.state.requestTourVdo} type="video/mp4" />
                            </video>

                        </center>
                        <button className="btn btn-primary btn-sm float-right"
                            onClick={(e) => {
                                this.handleRequestTour()
                            }}
                        >
                            Request a Tour Build
                        </button>
                    </Modal.Body>
                </Modal>

                {/* Popup to play video */}
                <Modal
                    show={this.state.videoModal}
                    onHide={this.handleVdoCloseModal}
                    center
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <h5 className="card-title">{this.state.vdo_desc}</h5>
                    </Modal.Header>

                    <Modal.Body>
                        {
                            this.state.hasvideo === true && (
                                <video width="750" height="500" controls autoPlay>
                                    <source src={this.state.vdo_link} type="video/mp4" />
                                </video>
                                //<iframe src={this.state.vdo_link} width="750" height="500" allow="autoplay"></iframe>
                            )
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.hasTour === true && (
                                <button
                                    className="btn btn-primary pull-right ml-2"
                                    type="button"
                                    onClick={() => {
                                        this.handleStartTour();
                                    }}
                                >
                                    Start Tour
                                </button>
                            )
                        }

                        <button
                            className="btn btn-default pull-right ml-2"
                            type="button"
                            onClick={() => { this.handleVdoCloseModal(); }}
                        >
                            Skip
                        </button>

                    </Modal.Footer>

                </Modal>


                <div ref={this.innerRef}>
                    {/* <Joyride
                        steps={this.state.steps.tour}
                        continuous={true}
                        run={this.state.run}
                        showProgress
                        showSkipButton
                        callback={e => this.handleJoyrideCallback(e)}
                        styles={{
                            options: {
                                primaryColor: '#58B07E ',
                                textColor: '#19456b',
                                zIndex: 2003,
                            }
                        }}
                    /> */}
                </div>

            </>
        );
    }
}
export default StartTour;