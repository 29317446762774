import http from "./httpService";
import client from "./serviceClient";
import { apiUrl, queryString } from "../../src/environment";
import Products from '../components/client/widgets/products';

let accessToken = "";

function updateHeader() {
  accessToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
}

export async function createProspect(
  a_guid,
  ab_number,
  first_name,
  last_name,
  email,
  phone_no,
  city,
  country_id,
  organization,
  referred_by,
  priority,
  address1,
  address2,
  address3,
  post_code,
  alternate_phone,
  website,
  lead_source,
  bank_account_name,
  bsb_number,
  account_number,
  trading_name,
  acn,
  incorporation,
  company_status,
  directors,
  shareholders,
  industry,
  product_id,
  product_amount,
  shareholdersData,
  addressList,
  organisation_number,
  organisation_status,
  alternate_email,
  owner,
  prospectType
) {
  updateHeader();

  const { data } = await http.post(
    apiUrl +
    "/prospect/prospects/create-prospect?XDEBUG_SESSION_START=PHPSTORM",
    {
      a_guid,
      ab_number,
      first_name,
      last_name,
      email,
      phone_no,
      city,
      country_id,
      organization,
      referred_by,
      priority,
      address1,
      address2,
      address3,
      post_code,
      alternate_phone,
      website,
      lead_source,
      bank_account_name,
      bsb_number,
      account_number,
      trading_name,
      acn,
      incorporation,
      company_status,
      directors,
      shareholders,
      industry,
      product_id,
      product_amount,
      shareholdersData,
      addressList,
      organisation_number,
      organisation_status,
      alternate_email,
      owner,
      prospectType
    },
    accessToken
  );
  return data;
}

export async function getTabsList(type) {
  updateHeader();
  let url =
    apiUrl + "/prospect/prospects/get-tabs-list?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { type };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function getProspectsList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl + "/prospect/prospects/list-prospects?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
    additionalParams,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

/********************* */

export async function getInvitedProspectsList(
  page,
  pageSize,
  sorted,
  filtered,
  search,
  additionalParams,
  handleRetrievedData
) {
  updateHeader();

  let url =
    apiUrl +
    "/prospect/prospects/fetch-invited-prospects?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    page: page,
    pageSize: pageSize,
    sorted: sorted,
    filtered: filtered,
    search: search,
    additionalParams,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return handleRetrievedData(response);
  } catch (response) {
    return console.log(response);
  }
}

/************************* */
export async function getProspectContactsList(entity, search = null, status) {
  updateHeader();

  let url =
    apiUrl +
    "/prospect/prospects/list-prospect-contacts?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity,
    search,
    status
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function getProspectNotesList(prospect_guid ,checkPriority) {
  updateHeader();

  let url =
    apiUrl +
    "/prospect/prospects/list-prospect-notes?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    prospect_guid,
    checkPriority,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function createProspectNote(
  note_guid,
  title,
  note,
  reference,
  entity,
  priority_note
) {
  updateHeader();

  let url =
    apiUrl +
    "/prospect/prospects/create-prospect-notes?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    note_guid,
    title,
    note,
    reference,
    entity,
    priority_note,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

// export async function createProspectContact(
//   contact_id,
//   first_name,
//   designation,
//   email,
//   phone_no,
//   entity_id,
//   selectedParentContact
// ) {
//   updateHeader();

//   const { data } = await http.post(
//     apiUrl +
//     "/prospect/prospects/create-prospect-contact?XDEBUG_SESSION_START=PHPSTORM",
//     {
//       contact_id,
//       first_name,
//       designation,
//       email,
//       phone_no,
//       entity_id,
//       selectedParentContact,
//     },
//     accessToken
//   );
//   return data;
// }

const createProspectContact = params => client.post(`/prospect/prospects/create-prospect-contact`, { ...params });

// fetchInviteWordSuggestions
export async function fetchKeywordSuggestions(search, additionalParams) {
  updateHeader();

  let url =
    apiUrl +
    "/prospect/prospects/key-word-auto-suggestions?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    search: search,
    additionalParams,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function getProspectDetails(a_guid) {
  updateHeader();

  let url =
    apiUrl +
    "/prospect/prospects/get-prospect-details?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    a_guid,
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function moveToEntity(categories, selectedEntities, products, isDraft = false) {
  updateHeader();

  let url =
    apiUrl +
    "/prospect/prospects/move-to-entities?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    categories,
    selectedEntities,
    products,
    isDraft
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function validateExcelSheet(prospects) {
  updateHeader();

  let url =
    apiUrl +
    "/prospect/prospects/validate-excel-sheet?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    prospects,
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function validateMigrationSheet(prospects, owner = null) {
  updateHeader();

  let url = apiUrl + "/prospect/prospects/validate-migration-sheet?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { prospects, owner };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function getMailTemplatesList(entity, type) {
  updateHeader();

  let url =
    apiUrl +
    "/core/mail-templates/fetch-templates?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { entity: entity, type: type };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response;
  } catch (response) {
    return console.log(response);
  }
}

export async function handleContactCheckboxChange(
  contact_guid,
  ischecked,
  field,
  entity
) {
  updateHeader();
  let url =
    apiUrl +
    "/prospect/prospects/prospect-contact-checkbox-change?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    contact_guid,
    ischecked,
    field,
    entity,
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function deleteProspectContact(contactId) {
  updateHeader();
  let url =
    apiUrl +
    "/prospect/prospects/delete-prospect-contact?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { contactId };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function deleteProspect(prospects) {
  updateHeader();
  let url =
    apiUrl +
    "/prospect/prospects/delete-prospect?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { prospects };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function bulkUpdate(params) {
  updateHeader();
  let url =
    apiUrl +
    "/prospect/prospects/prospect-bulk-update?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { ...params };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function deleteProspectInvitation(a_guid) {
  updateHeader();
  let url =
    apiUrl +
    "/prospect/prospects/delete-prospect-invitation?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = { a_guid };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function sendProspectInvitationMail(mail_to, mail_body) {
  updateHeader();

  let url =
    apiUrl +
    "/prospect/prospects/send-prospect-invitation-mail?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    mail_to,
    mail_body
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return response.data;
  }
}

/*** API FOR prospect invitation  */

export async function saveInvitation(data) {
  updateHeader();
  let url =
    apiUrl +
    "/prospect/prospects/prospect-invitation?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    first_name: data.first_name,
    email_id: data.email_id,
    source: data.source,
    last_name: data.last_name,
    organization: data.organization,
  };

  try {
    const response = await http.post(url, postObject, accessToken);
    return response.data;
  } catch (response) {
    return console.log(response);
  }
}

export async function getEntityDefaults(entityId) {
  updateHeader();

  let url =
    apiUrl + "/prospect/prospects/get-defaults?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entityId,
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

export async function createDefaults(
  entity_id,
  contact_date,
  contact_remainder,
  type
) {
  updateHeader();
  let url =
    apiUrl +
    "/prospect/prospects/create-defaults?XDEBUG_SESSION_START=PHPSTORM";
  let postObject = {
    entity_id,
    contact_date,
    contact_remainder,
    type
  };

  try {
    const { data } = await http.post(url, postObject, accessToken);
    return data;
  } catch (data) {
    return console.log(data);
  }
}

const listProspects = (params, status) => client.post(`/prospect/prospects/list-prospects`, { ...params, status });
const searchProspects = params => client.post(`/prospect/prospects/search-prospects`, { ...params });
const saveProspectCoreFields = params => client.post(`/prospect/prospects/create-prospect`, { ...params });
const bulkUpload = params => client.post(`/prospect/prospects/bulk-upload`, { ...params });
const getReactivationReasons = params => client.post(`/prospect/prospects/fetch-reactivation-reasons`, { ...params });
const fetchProspectTasks = params => client.post(`/prospect/prospects/get-tasks-list?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const createProspectTask = params => client.post(`/prospect/prospects/create-prospect-task?XDEBUG_SESSION_START=PHPSTORM`, { ...params });
const quickSearch = params => client.post(`/prospect/prospects/quick-prospect-search`, { ...params });
const notifySameDomain = params => client.post(`/prospect/prospects/notify-same-domain`, { ...params });

const ChangeOwner = params => client.post(`/prospect/prospects/change-owner`, { ...params });

const bulkMigration = params => client.post(`/prospect/prospects/bulk-migration`, { ...params });

export default {
  createProspect,
  getProspectsList,
  getProspectDetails,
  moveToEntity,
  bulkUpload,
  bulkMigration,
  getTabsList,
  validateExcelSheet,
  fetchKeywordSuggestions,
  getProspectContactsList,
  createProspectContact,
  getMailTemplatesList,
  getProspectNotesList,
  createProspectNote,
  handleContactCheckboxChange,
  deleteProspectContact,
  saveInvitation,
  getInvitedProspectsList,
  deleteProspectInvitation,
  sendProspectInvitationMail,
  deleteProspect,
  bulkUpdate,
  createDefaults,
  getEntityDefaults,
  listProspects,
  searchProspects,
  saveProspectCoreFields,
  getReactivationReasons,
  fetchProspectTasks,
  createProspectTask,
  quickSearch,
  notifySameDomain,
  ChangeOwner,
  validateMigrationSheet
};
