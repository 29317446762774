import React, { useState, useContext, useEffect } from "react";
import { WhiteCardTypeOne } from "../../components/cards";
import { AddButton } from "../../../common/containers/button";
import EntityContext from "../../../../context/entityContext";
import authService from "../../../../services/authService";
import Modal from "react-bootstrap/Modal";
import NewTrader from "../../pages/scTransactions/tradersManagement/newTrader";
import RoleChecker from "../../../../utils/roleChecker";
import CommonService from "../../../supply-chain/services/commonService";
import useApi from "../../../../hooks/useApi";
import useIsMounted from "../../../../hooks/useIsMounted";
import { FormColumnMd } from "../../../common/containers/formContainer";
import ReactCardFlip from "react-card-flip";

function SupplierCustomerManagement(props) {
  const isMounted = useIsMounted();
  const [flipped, setFlipped] = useState(false);
  const [tradersData, setTradersData] = useState(null);
  const [open, setOpen] = useState(false);
  const { selectedBusinessType, entityDetails, terminology } =
    useContext(EntityContext);
  const { constants } = authService.getCurrentUser();
  const products = entityDetails.products;
  let addBtn = "";
  let bulkUploadBtn = "";

  const getScUserCountApi = useApi(CommonService.getScUserCount);

  if (
    constants.ROLES &&
    RoleChecker.check(products, constants.ROLES.CUSTOMER) &&
    selectedBusinessType == "Purchase"
  ) {
    addBtn = (
      <AddButton
        onClick={() => setOpen(true)}
        style="btn  btn-block btn-primary mb-1 mr-2"
        label={`Add ${terminology && terminology.SUPPLIER}`}
      ></AddButton>
    );
    bulkUploadBtn = (
      <AddButton
        route="/client/scf/supplier-bulk-upload"
        style="btn  btn-block btn-secondary  mb-1 ml-2"
        label=" Bulk Upload"
      ></AddButton>
    );
  } else {
    addBtn = "";
  }
  const fetchTraderData = async () => {
    const { data } = await getScUserCountApi.request({
      product_type: constants.PRODUCTS.SUPPLYCHAIN,
      userType: selectedBusinessType,
    });
    const topLabel =
      selectedBusinessType == constants.BUSINESS_TYPE.SALE
        ? `${terminology && terminology.CUSTOMER}s`
        : `${terminology && terminology.SUPPLIER}s`;
    const cardTitle =
      selectedBusinessType == constants.BUSINESS_TYPE.SALE
        ? `${terminology && terminology.CUSTOMER} Management`
        : `${terminology && terminology.SUPPLIER} Management`;

    if (isMounted()) {
      const { customerCount, settings } = data;
      setTradersData({
        cardTitle: cardTitle,
        topLabel: topLabel,
        topValue: customerCount.total ? customerCount.total : 0,
        leftLabel: "Active",
        leftValue: customerCount.activeCount ? customerCount.activeCount : 0,
        rightLabel: "Inactive",
        rightValue: customerCount.inactiveCount
          ? customerCount.inactiveCount
          : 0,
        btnRoute: "/client/scf/mapped-users",
        settings: settings,
      });
    }
  };

  const flipCard = () => {
    setFlipped(!flipped);
  };

  useEffect(() => {
    fetchTraderData();
  }, [selectedBusinessType]);

  const onCloseModal = () => {
    setOpen(false);
    fetchTraderData();
  };

  return (
    <>
      <div className="col-lg-12">
        <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
          <FormColumnMd col={12} noCard={true}>
            <div className="card">
              <div className="card-body">
                <span className="b-corner b-corner-r b-corner-secondary" />
                <div className="d-flex justify-content-between">
                  <h6 className="card-title ">
                    {tradersData ? tradersData.topLabel : ""}
                  </h6>
                  <div className="ml-auto">
                    <div className="btn-group ml-5 mb-0">
                      <a
                        title="Click here to flip the card"
                        className="cursor-link"
                        onClick={flipCard}
                      >
                        <i className="fa fa-refresh " />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="mb-1 mt-2 d-flex align-items-center">
                      <div className="w-10 h-10 bg-primary rounded-full mr-2 " />
                      {tradersData ? tradersData.leftLabel : ""}
                    </div>
                  </div>

                  <div className="col col-auto">
                    <h6 className="mb-0">
                      {tradersData ? tradersData.leftValue : ""}
                    </h6>
                  </div>
                </div>

                <div className="row justify-content-center mt-4">
                  {tradersData &&
                    addBtn &&
                    (tradersData.settings ? (
                      <>
                        {" "}
                        {addBtn} {bulkUploadBtn}{" "}
                      </>
                    ) : (
                      <span className="text-danger">
                        Rate settings are not configured
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </FormColumnMd>

          <FormColumnMd col={12} noCard={true}>
            <div className="card">
              <div className="card-body">
                <span className="b-corner b-corner-r b-corner-secondary" />
                <div className="d-flex justify-content-between">
                  <h6 className="card-title ">
                    {tradersData ? tradersData.topLabel : ""}
                  </h6>
                  <div className="ml-auto">
                    <div className="btn-group ml-5 mb-0">
                      <a
                        title="Click here to flip the card"
                        className="cursor-link"
                        onClick={flipCard}
                      >
                        <i className="fa fa-refresh " />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="mb-1 mt-2 d-flex align-items-center">
                      <div className="w-10 h-10 bg-danger rounded-full mr-2 " />
                      {tradersData ? tradersData.rightLabel : ""}
                    </div>
                  </div>

                  <div className="col col-auto">
                    <h6 className="mb-0">
                      {tradersData ? tradersData.rightValue : ""}
                    </h6>
                  </div>
                </div>

                <div className="row justify-content-center mt-4">
                  {tradersData &&
                    addBtn &&
                    (tradersData.settings ? (
                      <>
                        {" "}
                        {addBtn} {bulkUploadBtn}{" "}
                      </>
                    ) : (
                      <span className="text-danger">
                        Rate settings are not configured
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </FormColumnMd>
        </ReactCardFlip>
      </div>

      <Modal
        show={open}
        onHide={() => setOpen(false)}
        //size="lg"
        centered
      >
        <Modal.Header closeButton>
          Add New {terminology ? terminology.SUPPLIER : "Supplier"}
        </Modal.Header>
        <Modal.Body>
          <NewTrader
            entity_id={entityDetails.a_guid}
            role={selectedBusinessType}
            title={terminology && terminology.SUPPLIER}
            source="network"
            onCloseModal={() => onCloseModal()}
            product={constants.PRODUCTS.SUPPLYCHAIN}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default SupplierCustomerManagement;
